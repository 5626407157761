import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Input from '../Input';

const MultiSelect = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Card = styled.div`
  position: relative;
  width: ${(props) => (props.childWidth ? props.childWidth + 'px' : '112px')};
  height: ${(props) => (props.childHeight ? props.childHeight + 'px' : '150px')};
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid rgba(21, 55, 96, 0.12);
  border-radius: 3px;
  background-color: ${(props) => props.theme.light};
  word-wrap: break-word;

  & .close-button {
    position: absolute;
    top: 1px;
    right: 5px;
    color: ${(props) => props.theme.gray_300};
    cursor: pointer;

    i {
      font-size: 16px;
    }

    :hover {
      color: ${(props) => props.theme.gray_500};
    }
  }

  & .add-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    color: ${(props) => props.theme.gray_400};

    :hover {
      color: ${(props) => props.theme.gray_600};
    }

    i {
      font-size: 30px;
      font-weight: bold;
    }

    span {
      font-size: 14px;
    }
  }
`;

const AddCard = styled(Card)`
  border-width: 0px;
  cursor: pointer;
`;

const DropDown = styled.div`
  z-index: 999;
  top: ${(props) => (props.childHeight ? props.childHeight + 3 + 'px' : '115px')};
  position: absolute;
  min-width: 350px;

  box-shadow: 0px 2px 5px 1px;
  background-color: #ffffff;
  border-radius: 2px;

  & .title {
    margin: 12px;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
  padding-left: 12px;
  min-height: 48px;
  border-bottom: 1px solid #0000001f;

  &:hover {
    background-color: ${(props) => props.theme.primary_50};
  }

  & .mark-icon {
    position: absolute;
    right: 24px;
    color: ${(props) => props.theme.secondary_400};
  }
`;

const OptionWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
`;

const InputWrapper = styled.div`
  padding: 6px;
`;

/*
example: options props
[{
    key: 1,
    value: 1, 
    text: "test1",
    args: ["A", "1"]
}]

example: template props
const template = (args) => <div>
  {args[0]}, {args[1]}
</div>
*/

export default ({ childWidth, childHeight, dropDownTitle, addIconTitle, template, options, onChange, value, disabled, onAddButtonOnClicked, onItemClicked }) => {
  const [selectedValue, setSelectedValue] = useState(value || []);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const removeItem = (e, index) => {
    e.stopPropagation();
    selectedValue.splice(index, 1);
    setSelectedValue(Object.assign([], selectedValue));

    if (onChange) {
      onChange(selectedValue);
    }
  };

  const openDropDown = () => {
    setIsOpen(true);
  };

  const optionClickHandler = (e) => {
    if (selectedValue && !selectedValue.find((selected) => selected.value === e.value)) {
      const newSelectedValue = Object.assign([], [...selectedValue, e]);
      setSelectedValue(Object.assign([], newSelectedValue));

      if (onChange) {
        onChange(newSelectedValue);
      }
    }
  };

  const itemClickHandler = (value) => {
    if (onItemClicked) {
      onItemClicked(value);
    }
  };

  const searchInputChangedHandler = (e) => {
    setSearch(e.target.value);
  };

  return (
    <React.Fragment>
      <MultiSelect>
        {selectedValue &&
          selectedValue.map((value, index) => (
            <Card key={index} childWidth={childWidth} childHeight={childHeight} onClick={() => itemClickHandler(value)}>
              {!disabled && (
                <span className='close-button' onClick={(e) => removeItem(e, index)}>
                  <i className='material-icons'>close</i>
                </span>
              )}
              {template ? template(value.args) : value.args[0]}
            </Card>
          ))}

        {!disabled && (
          <AddCard onClick={onAddButtonOnClicked ? onAddButtonOnClicked : openDropDown} childWidth={childWidth} childHeight={childHeight}>
            <div className='add-icon'>
              <i className='material-icons add'>add</i>
              <span>{addIconTitle}</span>
            </div>
            {isOpen && (
              <div ref={wrapperRef}>
                <DropDown>
                  <div className='sub1 title'>{dropDownTitle}</div>
                  <InputWrapper>
                    <Input value={search} onChange={searchInputChangedHandler} />
                  </InputWrapper>
                  <OptionWrapper>
                    {options &&
                      options.map(
                        (option) =>
                          (!search || option.text.indexOf(search) >= 0) && (
                            <Option key={option.key} onClick={() => optionClickHandler(option)}>
                              <div className='sub1'>{option.text}</div>
                              {selectedValue && selectedValue.find((selected) => selected.value === option.value) && (
                                <div className='mark-icon'>
                                  <i className='material-icons checked'>check_circle</i>
                                </div>
                              )}
                            </Option>
                          )
                      )}
                  </OptionWrapper>
                </DropDown>
              </div>
            )}
          </AddCard>
        )}
      </MultiSelect>
    </React.Fragment>
  );
};
