import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import hgLogoColor from '../../assets/images/hg_logo-color.svg';
import { Content } from '../ui';

const Wrapper = styled.div`
  background-color: ${props => props.theme.bg};
`

const Header = styled.header`
  background-color: ${props => props.theme.light};
  border-bottom: 1px solid ${props => props.theme.gray_500};
  height: 96px;
  display: flex;
  justify-content: center;

  div {
    max-width: 1201px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      height: 72px;
      margin-right: 40px;
    }

    span.headline5 {
      color: ${props => props.theme.primary};
    }
  }
`

const Body = styled.div`
  background-color: ${props => props.theme.bg};
  max-width: 1201px;
  width: 100%;
  margin: 24px auto;

  table, th, td {
    border-collapse: collapse;
    border: 1px solid ${props => props.theme.dark};
  }
  
  table {
    border-collapse: collapse
  }

  th {
    background: ${props => props.theme.dark};
  }

  th, td {
    padding: 12px;
    vertical-align: middle;
  }
`
export default () => (
  <Wrapper>
    <Header>
      <div>
        <Link to="/"><img src={hgLogoColor} alt="HiveGrid Logo" /></Link>
      </div>
    </Header>
    <Body>
      <Content>
        <h4>HiveGround Privacy Policy</h4>
        <p>This Privacy policy is intended to help you understand:</p>
        <ul>
          <li>Information we collect</li>
          <li>How we use the information we collect</li>
          <li>Legal bases for processing (for EEA users)</li>
          <li>When we share information</li>
          <li>How to access and control your information</li>
          <li>Privacy Shield Notice</li>
          <li>Links to other sites</li>
          <li>How we secure information we collect</li>
          <li>How we transfer information we collect internationally</li>
          <li>Children</li>
          <li>Updates to this policy</li>
          <li>How to contact us</li>
        </ul>
        <p>This Privacy Policy (the “Policy”)  describes the information we collect about you when you use our products, services, or otherwise communicate with us.  HiveGround Co. Ltd. (“HiveGround”, “we” or “us”) refers to and our affiliates. Our hardwares, Web Services, and all related applications, software, tools will be referred to in this policy as our “Services”. You control the information you provide to us, and we will always strive to process your information consistently with the purposes for which you’ve engaged us.</p>
        <p>This policy also explains your choices about how we use information about you, including how you can object to certain uses of the data, as well as how you can access or update certain information about you<strong>. Please do not use our Services, if you do not agree with this policy</strong>.</p><p>“Personal Information,” as used in this Policy, is information that specifically identifies an individual, such as an individual's name, address, telephone number, or email address. Personal Information also includes information about an individual’s activities, such as information about his or her activity on our Service, and demographic information, such as date of birth, gender, geographic area, and preferences, when any of this information is linked to Personal Information that identifies that individual.</p>
        <p>Personal Information does not include “aggregate” or other non-personally identifiable information. Aggregate information is information that we collect about a group or category of products, Service, or users that is not personally identifiable or from which individual identities are removed.</p>
        <h4>Information Collected</h4>
        <h5>Voluntarily-Provided Information</h5>
        <p>We primarily collect information about you both when you provide it to us directly, or use our Services.</p>
        <ul>
          <li>Our Services give you the ability to register for a HiveGrid account, or to create and update a user profile.  We will collect the personal information provided during the course of registration and profile creation and update process.  This information may include name, postal address, telephone number, email address, and related demographic information.</li>
          <li>Our Services collect geolocation information when you use our mobile application, including your physical location, such as street address, latitude and longitude, and internet protocol address, and the time of recording the location or the duration at that location.</li>
          <li>Our Services collect data from mobile application and unmanned aerial vehicles (“UAV” or “Drones”).  The information includes telemetry data, map data, location and data regarding the systems of vehicles.</li>
          <li>In case that you contact us through any communication channels including email, phone calls, or otherwise reach out to us, we will gather information you provided in order to best answer your questions.</li>
        </ul>
        <h5>Passive Information Collection</h5>
        <p>Some information is collected automatically when you use our services.  For example, we automatically collect your browser’s Internet Protocol (IP), your browser type, the nature of the device from which you are visiting the Service (e.g., a personal computer or a mobile device), the identifier for any handheld or mobile device that you may be using, the Web site that you visited immediately prior to accessing any Web-based Service, the actions you take on our Services, and the content, features, and activities that you access and participate in on our Services. We also may collect information regarding your interaction with e-mail messages, such as whether you opened, clicked on, or forwarded a message. When you operate an UAV via the Services, we automatically collect, among other information, information identifying the vehicle, flight path, flight logs and sensor data, where applicable.</p>
        <p>We may collect this information passively using technologies such as standard server logs, cookies, and clear GIFs (also known as “Web beacons”) and geolocation tools. We use passively-collected information to administer, operate, and improve our Services and systems, and to provide other content that is tailored to you.</p>
        <p>If we link or associate any information gathered through passive means with Personal Information, or if applicable laws require us to treat any information gathered through passive means as Personal Information, we treat the combined information as Personal Information under this Policy. Otherwise, we use and disclose information collected by passive means in aggregate form or otherwise in a non-personally identifiable form. Please be aware that in the course of your use of the Services, websites or other Services provided by third parties (&quot;Third-Party Services&quot;), including marketing or website optimization vendors, may set “cookies” on your hard drive or use other means of passively collecting information about your use of their Third-Party Services or other Services or content. We also may make non-personally identifiable information available to Third-Party Services, and these Third-Party Services may collect such information, to assist them in understanding our users’ activities and usage patterns on the Services. If desired, you may use the Google Analytics Opt-out Browser Add-on to opt-out of having information collected by Google Analytics.</p><p>We do not have access to, or control over, the actions of Third-Party Services. Each provider of Third-Party Services uses information that it collects in accordance with its own privacy and security policies.</p>
        <h5>Information from Other Sources</h5>
        <p>We may receive information about you, including Personal Information, from third parties, and may combine this information with other Personal Information we maintain about you. If we do so, this Policy governs any combined information that we maintain in personally identifiable format.</p>
        <h5>Information that We Share</h5>
        <p>Except as described in this Policy, we will not disclose your Personal Information to third parties without your consent. These exceptions include:</p>
        <h5>Service Providers</h5>
        <p>We may disclose Personal Information to third-party Service providers (such as for payment processing) which assist us in providing our services to you. We limit the Personal Information provided to these Service providers to that which is reasonably necessary for them to perform their functions, and we require them to agree to maintain the confidentiality of such Personal Information.</p>
        <h5>Public areas and related third-party services</h5>
        <p>Some features on our Services may allow you to upload User Materials to public areas of the Services. We may make these User Materials available to Services operated by third parties which display content from our Services. Please be aware that any Personal Information that you include within any User Materials will be made available to other users and to these third-party services, who view it.</p>
        <h5>Business Transfers</h5>
        <p>Information about our users, including Personal Information, may be disclosed and otherwise transferred to an acquirer, or successor or assignee as part of any merger, acquisition, debt financing, sale of HiveGround assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which Personal Information is transferred to one or more third parties as one of our business assets.</p>
        <h5>Others</h5>
        <p>We also disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements, or it is in our interest to protect our property or other legal rights (including, but not limited to, enforcement of our agreements), or the rights or property of others, or otherwise to help protect the safety or security of our Services and other users of the Services.</p>
        <p>You may access and control the information about you which we gather. You may access and update many categories of personal information by logging in to your account and accessing your account settings. If you wish to access, amend or delete any other personal information we hold about you, you may contact us at <a href="mailto:support@hiveground.com">support@hiveground.com</a>.</p>
        <p>If you receive commercial email from us, you may unsubscribe at any time by following the instructions contained within the email, or by sending us an email at <a href="mailto:support@hiveground.com">support@hiveground.com</a>. Additionally, you may view and modify settings relating to the nature and frequency of promotional communications that you receive from us.</p>
        <p>Please be aware that if you opt-out of receiving commercial email from us, it may take up to ten business days for us to process your opt-out request, and you may receive commercial email from us during that period. Additionally, even after you opt-out from receiving commercial messages from us, you will continue to receive administrative messages from us regarding our Service.</p>
        <p>If you request that we delete your account on any of our Services, we will do so within a reasonable period of time, but we may need to retain some of your Personal Information if necessary to satisfy our legal obligations, or where we reasonably believe that we have a legitimate reason to do so.</p>
        <h4>Links to Third-Party Services</h4>
        <p>Our Services may contain links to other websites, products, or services that we do not own or operate. If you choose to visit or use any Third-Party Sites or products or services available on or through such Third-Party Sites, please be aware that this Policy will not apply to your activities or any information you disclose while using those Third-Party Sites or any products or services available on or through such Third-Party Sites. We are not responsible for the privacy practices of these Third-Party Sites or any products or services on or through them.</p>
        <h4>Security of Information Collected</h4>
        <p>Our Services incorporate privacy by design, including technical and organizational measures to ensure we are providing the confidentiality and integrity which your data deserves. If there ever is a breach of those data, we will notify you as quickly as possible, as required by regulatory requirements.</p>
        <h4>Updates to this Policy</h4>
        <p>We occasionally update this Policy. When we do, we will also revise the “last updated” date at the beginning of the Policy. Your continued use of our Service after such changes will be subject to the then-current policy. We encourage you to periodically review this Policy to stay informed about how we collect, use, and disclose your information.</p>
      </Content>
    </Body>
  </Wrapper>
)