import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import languageConverter from '../util/languageConverter';

const mapStateToProps = (state) => {
  return {
    lang: state.auth.userInfo && state.auth.userInfo.preference && state.auth.userInfo.preference.language,
  };
};

const withIntl = (namespace = 'translation') =>
  compose(connect(mapStateToProps), withTranslation(namespace), (WrappedComponent) => {
    return class extends Component {
      static getDerivedStateFromProps(props, state) {
        if (!state.lang || props.lang !== state.lang) {
          return {
            lang: props.lang ? languageConverter(props.lang) : state.lang,
          };
        }
      }

      constructor(props) {
        super(props);
        this.state = {
          lang: props.lang ? languageConverter(props.lang) : 'en',
        };
        this.setLanguage = this.setLanguage.bind(this);
      }

      setLanguage(lang) {
        this.setState({ lang });
      }

      componentDidUpdate(prevProps, prevState) {
        if (!this.state.lang || prevState.lang !== this.state.lang) {
          const { i18n } = this.props;
          const { lang } = this.state;
          i18n.changeLanguage(lang);
          moment.locale(lang);
        }
      }

      render() {
        return <WrappedComponent {...this.props} setLanguage={this.setLanguage} />;
      }
    };
  });

export default withIntl;
