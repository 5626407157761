import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { verifyRegistration } from '../../redux/registration';
import { Button } from '../ui';
import hgLogoColor from '../../assets/images/hg_logo-color.svg';
import congratImg from '../../assets/images/congrats@2x.png';
import incorrectImg from '../../assets/images/sad-tear-regular.svg';
import { Steps } from '../ui';

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 4px;
  background-color: ${(props) => props.theme.light};
  padding: 36px 16px;
  width: 773px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 16px;
  grid-template-rows: repeat(4, auto);

  h4 {
    color: ${(props) => props.theme.primary};
    grid-column: 1 / span 8;
    grid-row: 1;
    text-align: center;
  }

  .sub1 {
    color: ${(props) => props.theme.gray_700};
    grid-column: 1 / span 8;
    grid-row: 2;
    text-align: center;
  }

  img {
    width: 200px;
    height: 200px;
    margin: 20px auto;
    grid-column: 1 / span 8;
    grid-row: 3;
  }
`;

const StyledButton = styled(Button)`
  grid-row: 4;
  grid-column: 2 / span 6;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.bg};
`;

const Header = styled.header`
  background-color: ${(props) => props.theme.light};
  border-bottom: 1px solid ${(props) => props.theme.gray_500};
  height: 96px;
  display: flex;
  justify-content: center;

  div {
    max-width: 1201px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      height: 72px;
      margin-right: 40px;
    }

    span.headline5 {
      color: ${(props) => props.theme.primary};
    }
  }
`;

const Body = styled.div`
  background-color: ${(props) => props.theme.bg};
  max-width: 1201px;
  width: 100%;
  margin: auto;
`;

const StepsWrapper = styled.div`
  width: 737px;
  margin: 24px auto;
`;
class CongratulationPage extends Component {
  componentDidMount() {
    const registrationKey = queryString.parse(this.props.location.search).registrationKey;
    this.props.verifyRegistration(registrationKey);
  }

  goToLoginPage = () => {
    localStorage.clear();
    this.props.history.replace('/login');
  };

  render() {
    const { isVerifySuccess, verifyError } = this.props;
    if (typeof isVerifySuccess === 'undefined') return null;
    return (
      <Wrapper>
        <Header>
          <div>
            <Link to='/'>
              <img src={hgLogoColor} alt='HiveGrid Logo' />
            </Link>
            <span className='headline5'>Create New Account</span>
          </div>
        </Header>
        <Body>
          <StepsWrapper>
            <Steps current={4}>
              <Steps.Step title='Basic Information' />
              <Steps.Step title='Admin Account' />
              <Steps.Step title='Privacy Policy' />
              <Steps.Step title='Confirmation' />
              <Steps.Step title='Finish' />
            </Steps>
          </StepsWrapper>
          <Content>
            <h4>{isVerifySuccess ? 'Congratulations!' : 'Oops!'}</h4>
            <p className='sub1'>{verifyError ? verifyError.message : 'Your account has successfully been set up.'}</p>
            <img src={isVerifySuccess ? congratImg : incorrectImg} alt={isVerifySuccess ? 'confetti' : 'X mark'} />
            <StyledButton onClick={this.goToLoginPage} style={{ width: '100%' }} id='button-reg-continue-login'>
              CONTINUE TO LOGIN PAGE
            </StyledButton>
          </Content>
        </Body>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isVerifySuccess: state.registration.isVerifySuccess,
    verifyError: state.registration.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyRegistration: (registrationKey) => dispatch(verifyRegistration(registrationKey)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CongratulationPage);
