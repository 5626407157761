import axios from '../axios';

// Actions
export const SUBSCRIBE_NEW_PLAN_SUCCESS = 'SUBSCRIBE_NEW_PLAN_SUCCESS';
export const SUBSCRIBE_NEW_PLAN_FAILED = 'SUBSCRIBE_NEW_PLAN_FAILED';
export const LOAD_SUBSCRIBE_PLAN_LIST_SUCCESS = 'LOAD_SUBSCRIBE_PLAN_LIST_SUCCESS';
export const LOAD_SUBSCRIBE_PLAN_LIST_FAILED = 'LOAD_SUBSCRIBE_PLAN_LIST_FAILED';
export const LOAD_ORGANIZATION_SUBSCRIPTION_LIST_SUCCESS = 'LOAD_ORGANIZATION_SUBSCRIPTION_LIST_SUCCESS';
export const LOAD_ORGANIZATION_SUBSCRIPTION_LIST_FAILED = 'LOAD_ORGANIZATION_SUBSCRIPTION_LIST_FAILED';
export const LOAD_ORGANIZATION_FACT_SUMMARY_LIST_SUCCESS = 'LOAD_ORGANIZATION_FACT_SUMMARY_LIST_SUCCESS';
export const LOAD_ORGANIZATION_FACT_SUMMARY_LIST_FAILED = 'LOAD_ORGANIZATION_FACT_SUMMARY_LIST_FAILED';
export const LOAD_SUBSCRIPTION_LOG_SUCCESS = 'LOAD_SUBSCRIPTION_LOG_SUCCESS';
export const LOAD_SUBSCRIPTION_LOG_FAILED = 'LOAD_SUBSCRIPTION_LOG_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_SUBSCRIBE_PLAN_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_SUBSCRIBE_PLAN_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SUBSCRIBE_NEW_PLAN_SUCCESS:
      return {
        ...state,
        subscribePlanIds: {
          ...state.subscription?.subscribePlanIds,
          [action.data.subscriptionPlanId]: action.data.status,
        },
      };
    case SUBSCRIBE_NEW_PLAN_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_ORGANIZATION_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        organizationSubscriptionList: action.data,
      };
    case LOAD_ORGANIZATION_SUBSCRIPTION_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_ORGANIZATION_FACT_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        organizationFactSummaryList: action.data,
      };
    case LOAD_ORGANIZATION_FACT_SUMMARY_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_SUBSCRIPTION_LOG_SUCCESS:
      return {
        ...state,
        subscribeLogs: action.data,
      };
    case LOAD_SUBSCRIPTION_LOG_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const subscribeNewPlan = (subscriptionPlanId) => {
  //  retrieve data from restApi
  // TODO try/catch handle on failed
  return (dispatch) => {
    axios
      .post(
        `/organization-subscriptions`,
        { subscriptionPlanId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        }
      )
      .then((response) => {
        console.log('object');
        dispatch({
          type: SUBSCRIBE_NEW_PLAN_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        console.log('object');
        dispatch({
          type: SUBSCRIBE_NEW_PLAN_FAILED,
          error,
        });
      });
  };
};

export const loadSubscribePlanList = () => {
  return (dispatch) => {
    axios
      .get(`/subscription-plans?includeFields=solution`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch({
          type: LOAD_SUBSCRIBE_PLAN_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_SUBSCRIBE_PLAN_LIST_FAILED,
          error,
        });
      });
  };
};

export const loadOrganizationSubscriptionList = () => {
  return (dispatch) => {
    axios
      .get(`/organization-subscriptions`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch({
          type: LOAD_ORGANIZATION_SUBSCRIPTION_LIST_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_ORGANIZATION_SUBSCRIPTION_LIST_FAILED,
          error,
        });
      });
  };
};

export const loadOrganizationFactSummaries = (organizationId) => {
  return (dispatch) => {
    axios
      .get(`/organizations/${organizationId}/organization-fact-summaries/active`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        params: {
          organizationId,
        },
      })
      .then((response) => {
        dispatch({
          type: LOAD_ORGANIZATION_FACT_SUMMARY_LIST_SUCCESS,
          data: response.data.facts,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_ORGANIZATION_FACT_SUMMARY_LIST_FAILED,
          error,
        });
      });
  };
};

export const loadSubscriptionLogs = (organizationId) => {
  return (dispatch) => {
    axios
      .get(`/organization-fact-summaries/organization-fact-logs`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch({
          type: LOAD_SUBSCRIPTION_LOG_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_SUBSCRIPTION_LOG_FAILED,
          error,
        });
      });
  };
};
