import axios from '../axios';

export const LOAD_FLIGHT_EVENT_DATA_SUCCESS = 'LOAD_FLIGHT_EVENT_DATA_SUCCESS';
export const LOAD_FLIGHT_EVENT_DATA_FAILED = 'LOAD_FLIGHT_EVENT_DATA_FAILED';

export const CLEAR_SELECTED_FLIGHT_EVENT_DATA = 'CLEAR_SELECTED_FLIGHT_EVENT_DATA';

const initialState = {
  all: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FLIGHT_EVENT_DATA_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_FLIGHT_EVENT_DATA_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SELECTED_FLIGHT_EVENT_DATA:
      return {
        ...state,
        all: undefined,
      };
    default:
      return state;
  }
}

export const loadFlightEventDataSuccess = (data) => {
  return {
    type: LOAD_FLIGHT_EVENT_DATA_SUCCESS,
    data,
  };
};

export const loadFlightEventDataFailed = (error) => {
  return {
    type: LOAD_FLIGHT_EVENT_DATA_FAILED,
    error,
  };
};

export const loadFlightEventData = () => {
  return (dispatch) => {
    axios
      .get(`/flight-event-data`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadFlightEventDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadFlightEventDataFailed(error));
      });
  };
};

export const clearSelectedFlightEventData = () => {
  return {
    type: CLEAR_SELECTED_FLIGHT_EVENT_DATA,
  };
};
