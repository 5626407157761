import axios from '../axios';

// Actions
export const LOAD_DRONE_MODEL_LIST_SUCCESS = 'LOAD_DRONE_MODEL_LIST_SUCCESS';
export const LOAD_DRONE_MODEL_LIST_FAILED = 'LOAD_DRONE_MODEL_LIST_FAILED';

export const LOAD_DRONE_MODEL_CONFIG_SUCCESS = 'LOAD_DRONE_MODEL_CONFIG_SUCCESS';
export const LOAD_DRONE_MODEL_CONFIG_FAILED = 'LOAD_DRONE_MODEL_CONFIG_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_DRONE_MODEL_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
        error: '',
      };

    case LOAD_DRONE_MODEL_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_DRONE_MODEL_CONFIG_SUCCESS:
      return {
        ...state,
        configurations: action.data,
      };

    case LOAD_DRONE_MODEL_CONFIG_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

// Action Creators
export const loadDroneModelListSuccess = (data) => {
  return {
    type: LOAD_DRONE_MODEL_LIST_SUCCESS,
    data,
  };
};
export const loadDroneModelListFailed = (error) => {
  return {
    type: LOAD_DRONE_MODEL_LIST_FAILED,
    error,
  };
};

export const loadDroneModelList = () => {
  return (dispatch) => {
    axios
      .get('/dronemodels', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadDroneModelListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadDroneModelListFailed(error));
      });
  };
};

export const loadDroneModelConfigurationSuccess = (data) => {
  return {
    type: LOAD_DRONE_MODEL_CONFIG_SUCCESS,
    data,
  };
};

export const loadDroneModelConfigurationFailed = (error) => {
  return {
    type: LOAD_DRONE_MODEL_CONFIG_FAILED,
    error,
  };
};

export const loadDroneModelConfiguration = () => {
  return (dispatch) => {
    axios
      .get(`/drone-model-configurations`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadDroneModelConfigurationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadDroneModelConfigurationFailed(error));
      });
  };
};
