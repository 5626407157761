import React, { Component } from 'react';
import styled from 'styled-components';
import Step from './Step';

const Wrapper = styled.div`
  display: flex;
`

class Steps extends Component {

  static Step = Step;

  render() {
    const { children, current } = this.props;
    return (
      <Wrapper>
        {
          React.Children.map(children, (child, idx) => {
            return React.cloneElement(child, {
              order: idx,
              currentOrder: current
            });
          })
        }
      </Wrapper>
    )
  }
}

export default Steps;