import styled from 'styled-components';
import placeholderLogo from '../../../../assets/images/placeholder_logo.svg';

const AvatarImage = styled.img`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${props => props.theme.primary_100};
  background-image: url(${placeholderLogo});
  object-fit: cover;

  :hover {
    background-color: ${props => props.theme.primary_200};
  }
`

AvatarImage.defaultProps = {
  size: 40,
  alt: ''
}

export default AvatarImage;