import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Field, Input, Button } from './ui';
import hgLogoColor from '../assets/images/hg_logo-color.svg';
import { compose } from 'recompose';
import withIntl from '../hoc/withIntl';
import NotFound from './NotFound';
import instance from '../axios';
import { languageMapper } from '../util/languageConverter';
import { has, isEmpty } from 'lodash';

const SpanButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.bg};
`;

const Header = styled.header`
  background-color: ${(props) => props.theme.light};
  height: 96px;
  display: flex;
  justify-content: center;

  div {
    max-width: 1201px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      height: 72px;
      margin-right: 40px;
    }

    span.headline5 {
      color: ${(props) => props.theme.primary};
    }
  }
`;

const Body = styled.div`
  background-color: ${(props) => props.theme.bg};
  width: 100%;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 0.1fr 0.2fr 0.6fr;
  grid-row-gap: 12px;
`;

const BodyField = styled.div`
  grid-row: 3;
  grid-column: 2/3;
`;

const ContentLayout = styled.div`
  grid-column: 3/6;
  grid-row: 2;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 0.2fr 0.4fr 1fr 0.4fr;
  display: grid;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.gray};
  box-shadow: ${(props) => props.theme.shadow_dept4};
`;

const TitleText = styled.h6`
  color: ${(props) => props.theme.primary};
  text-align: center;
  grid-column: 2;
  grid-row: 2;
`;

const NotfoundSection = styled.div`
  grid-column: 4;
  grid-row: 2;
`;

const SuccessTextArea = styled.p`
  text-align: center;
`;

class ResetPasswordByKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: {
        value: '',
        errorMessage: '',
      },
      confirmPassword: {
        value: '',
        errorMessage: '',
      },
      isKeyValid: false,
      usedToConfirm: false,
      updatePasswordResponse: {},
      isWaitingResetPasswordResponse: false,
    };
  }

  componentDidMount() {
    const resetPasswordKey = new URLSearchParams(this.props.location.search).get('key');
    const apiKey = process.env.REACT_APP_INTERNAL_API_KEY || 'internal-api-key';
    instance
      .post(`/verify-reset-password-key`, { key: resetPasswordKey }, { headers: { Authorization: apiKey, internal: apiKey } })
      .then((res) => {
        if (res.status === 201) {
          this.props.setLanguage(languageMapper[res.data.language]);
          this.setState({ isKeyValid: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleNewPasswordChange = (e) => {
    this.setState({
      newPassword: {
        value: e.target.value,
        errorMessage:
          this.state.usedToConfirm && (e.target.value === '' || e.target.value.length < 6)
            ? this.props.t('reset-password-by-key:error_message.length')
            : '',
      },
    });
  };

  handleConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: {
        value: e.target.value,
        errorMessage:
          this.state.usedToConfirm && this.state.newPassword.value !== e.target.value
            ? this.props.t('reset-password-by-key:error_message.not_match')
            : '',
      },
    });
  };

  handleConfirm = () => {
    this.setState({ usedToConfirm: true });
    if (!this.isPassCriterion()) {
      // verified new password field
      this.setState({
        newPassword: {
          ...this.state.newPassword,
          errorMessage:
            this.state.newPassword.value === '' || this.state.newPassword.value.length < 6
              ? this.props.t('reset-password-by-key:error_message.length')
              : '',
        },
      });
      // verified confirm password field
      this.setState({
        confirmPassword: {
          ...this.state.confirmPassword,
          errorMessage:
            this.state.newPassword.value !== this.state.confirmPassword.value
              ? this.props.t('reset-password-by-key:error_message.not_match')
              : '',
        },
      });

      return false;
    }

    // update new password to backend
    this.setState({ isWaitingResetPasswordResponse: true });
    const resetPasswordKey = new URLSearchParams(this.props.location.search).get('key');
    const apiKey = process.env.REACT_APP_INTERNAL_API_KEY || 'internal-api-key';
    instance
      .post(
        `/reset-password-by-key`,
        { key: resetPasswordKey, newPassword: this.state.newPassword.value },
        { headers: { Authorization: apiKey, internal: apiKey } }
      )
      .then((res) => {
        this.setState({ updatePasswordResponse: res, isWaitingResetPasswordResponse: false });
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.setState({ updatePasswordResponse: error, isWaitingResetPasswordResponse: false });
        }, 345);
      });
  };

  isPassCriterion = () => {
    if (this.state.newPassword.value === '' || this.state.confirmPassword.value === '') {
      return false;
    }
    if (this.state.newPassword.value.length < 6) {
      return false;
    }
    if (this.state.newPassword.value !== this.state.confirmPassword.value) {
      return false;
    }
    return true;
  };

  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        <Header>
          <div>
            <Link to='/'>
              <img src={hgLogoColor} alt='HiveGrid Logo' />
            </Link>
          </div>
        </Header>
        <Body>
          {this.state.isKeyValid ? (
            !isEmpty(this.state.updatePasswordResponse) ? (
              <ContentLayout>
                <TitleText>
                  {has(this.state.updatePasswordResponse, 'data.ok')
                    ? t('reset-password-by-key:success_title')
                    : t('reset-password-by-key:fail_title')}
                </TitleText>
                <BodyField>
                  <Field>
                    <SuccessTextArea>
                      {has(this.state.updatePasswordResponse, 'data.ok')
                        ? t('reset-password-by-key:success_text')
                        : t('reset-password-by-key:fail_text')}
                    </SuccessTextArea>
                  </Field>
                  <NavLink to='/'>
                    <SpanButton colorTheme='primary'>{t('reset-password-by-key:back_to_home')}</SpanButton>
                  </NavLink>
                </BodyField>
              </ContentLayout>
            ) : (
              <ContentLayout>
                <TitleText>{t('reset-password-by-key:title')}</TitleText>
                <BodyField>
                  <Field
                    title={t('reset-password-by-key:form.new_password.label')}
                    isRequired={true}
                    errorMessage={this.state.newPassword.errorMessage}>
                    <Input
                      type='password'
                      value={this.state.newPassword.value}
                      onChange={this.handleNewPasswordChange}
                      autoComplete='off'
                    />
                  </Field>
                  <Field
                    title={t('reset-password-by-key:form.confirm_password.label')}
                    isRequired={true}
                    errorMessage={this.state.confirmPassword.errorMessage}>
                    <Input
                      type='password'
                      value={this.state.confirmPassword.value}
                      onChange={this.handleConfirmPasswordChange}
                      autoComplete='off'
                    />
                  </Field>
                  <Field>
                    <SpanButton
                      onClick={this.handleConfirm}
                      colorTheme='primary'
                      spinnerColor={'white'}
                      isLoading={this.state.isWaitingResetPasswordResponse}>
                      {t('reset-password-by-key:confirm')}
                    </SpanButton>
                  </Field>
                </BodyField>
              </ContentLayout>
            )
          ) : (
            <NotfoundSection>
              <NotFound />
            </NotfoundSection>
          )}
        </Body>
      </Wrapper>
    );
  }
}

export default compose(withIntl('reset-password-by-key'))(ResetPasswordByKey);
