import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
  user-select: none;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default ({ data }) => (
  <Wrapper>
    <div className='sub1'>{data.text}</div>
    <div className='body2'>{data.subtext}</div>
  </Wrapper>
);
