import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import hgLogoColor from '../../assets/images/hg_logo-color.svg';
import { Steps } from '../ui';
import { startRegistration } from '../../redux/registration';
import OrganizationForm from './OrganizationForm';
import AdminUserForm from './AdminUserForm';
import ReviewPrivacyPolicyPage from './ReviewPrivacyPolicyPage';
import ConfirmationPage from './ConfirmationPage';
import CongratulationPage from './CongratulationPage';
import { isUndefined } from 'lodash';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.bg};
`;

const Header = styled.header`
  background-color: ${(props) => props.theme.light};
  border-bottom: 1px solid ${(props) => props.theme.gray_500};
  height: 96px;
  display: flex;
  justify-content: center;

  div {
    max-width: 1201px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      height: 72px;
      margin-right: 40px;
    }

    span.headline5 {
      color: ${(props) => props.theme.primary};
    }
  }
`;

const Body = styled.div`
  background-color: ${(props) => props.theme.bg};
  max-width: 1201px;
  width: 100%;
  margin: auto;
`;

const StepsWrapper = styled.div`
  width: 737px;
  margin: 24px auto;
`;

const stepMapping = [
  ['Basic Information', <OrganizationForm />],
  ['Admin Account', <AdminUserForm />],
  ['Privacy Policy', <ReviewPrivacyPolicyPage />],
  ['Confirmation', <ConfirmationPage />],
  ['Finish', <CongratulationPage />],
];

const RegistrationLayout = (props) => {
  const { currentPage, startRegistration } = props;

  useEffect(() => {
    startRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isUndefined(currentPage)) {
    return <></>;
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <Link to='/'>
            <img src={hgLogoColor} alt='HiveGrid Logo' />
          </Link>
          <span className='headline5'>Create New Account</span>
        </div>
      </Header>
      <Body>
        <StepsWrapper>
          <Steps current={currentPage}>
            {stepMapping.map((step) => (
              <Steps.Step title={step[0]} />
            ))}
          </Steps>
        </StepsWrapper>
        {stepMapping[currentPage][1]}
      </Body>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    currentPage: state.registration.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startRegistration: () => dispatch(startRegistration()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationLayout);
