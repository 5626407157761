import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import SpinnerInline from '../feedback/SpinnerInline';

const spinnerSizeMapper = {
  small: '4px',
  medium: '5px',
  large: '6px',
  'extra-large': '8px',
};

const StyledSpinnerWrapper = styled.div`
  display: flex;
  margin-left: ${(props) => {
    switch (props.size) {
      case 'small':
        return '5px';
      case 'medium':
        return '8px';
      case 'large':
        return '12px';
      case 'extra-large':
        return '18px';
      default:
        return '12px';
    }
  }};
`;

const MaterialIcon = styled.div.attrs({ className: 'material-icons' })`
  margin-right: 0.5em;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button.attrs({
  className: 'button',
})`
  padding-left: ${(props) => {
    if (props.colorTheme === 'text') {
      return 'none';
    } else if (props.colorTheme !== 'link') {
      return '16px';
    }
  }};

  padding-right: ${(props) => {
    if (props.colorTheme === 'text') {
      return 'none';
    } else if (props.colorTheme !== 'link') {
      return '16px';
    }
  }};

  width: fit-content;

  font-size: ${(props) => {
    if (props.colorTheme === 'link') {
      return '14px';
    }
  }};
  text-align: ${(props) => (props.colorTheme === 'link' ? 'left !important' : 'center')};

  letter-spacing: ${(props) => {
    if (props.colorTheme === 'link') {
      return '1px';
    }
  }};

  min-width: ${(props) => {
    if (props.colorTheme === 'link') {
      return;
    }

    switch (props.size) {
      case 'extra-small':
        return '45px';
      case 'small':
        return '80px';
      case 'medium':
        return '104px';
      case 'large':
        return '128px';
      default:
        return '128px';
    }
  }};
  outline: none;
  height: ${(props) => {
    if (props.colorTheme === 'link') {
      return;
    }

    switch (props.size) {
      case 'extra-small':
        return '35px';
      case 'small':
        return '24px';
      case 'medium':
        return '32px';
      case 'large':
        return '40px';
      case 'extra-large':
        return '48px';
      default:
        return '40px';
    }
  }};
  background-color: ${(props) => {
    switch (props.colorTheme) {
      case 'primary':
        return props.disabled ? props.theme.gray_500 : props.theme.primary_800;

      case 'text':
        return props.disabled ? props.theme.gray_500 : 'transparent';

      case 'outlined':
      case 'link':
        return 'transparent';

      default:
        return 'transparent';
    }
  }};
  color: ${(props) => {
    switch (props.colorTheme) {
      case 'primary':
        return props.disabled ? props.theme.white_text_disabled : props.theme.white;

      case 'outlined':
        return props.disabled ? props.theme.black_text_disabled : props.theme.primary;
        
      case 'text':
        return props.disabled ? props.theme.black_text_disabled : props.theme.primary;

      case 'link':
        return props.disabled ? props.theme.black_text_disabled : props.theme.gray_800;

      default:
        return props.disabled ? props.theme.black_text_disabled : props.theme.primary;
    }
  }};
  border: ${(props) => {
    switch (props.colorTheme) {
      case 'primary':
        return 'none';

      case 'outlined':
        return `1px solid ${props.theme.border_line}`;

      case 'text':
      case 'link':
      case 'transparent':
        return 'none';

      default:
        return `1px solid ${props.theme.border_line}`;
    }
  }};
  text-transform: ${(props) => (props.colorTheme === 'link' ? 'none' : 'uppercase')};
  border-radius: 4px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};

  cursor: ${(props) => {
    return props.disabled ? 'default' : 'pointer';
  }};

  &:hover {
    background-color: ${(props) => {
      switch (props.colorTheme) {
        case 'primary':
          return props.disabled ? props.theme.gray_500 : props.theme.primary_700;

        case 'outlined':
          return props.disabled ? 'transparent' : rgba(props.theme.primary_700, 0.04);

        case 'text':
          return props.disabled ? 'transparent' : rgba(props.theme.primary_700, 0.04);

        case 'link':
          return 'transparent';

        default:
          return props.disabled ? 'transparent' : rgba(props.theme.primary_700, 0.04);
      }
    }};
    color: ${(props) => {
      switch (props.colorTheme) {
        case 'primary':
          return props.disabled ? props.theme.white_text_disabled : props.theme.white;

        case 'text':
        case 'link':
        case 'outlined':
          return props.disabled ? props.theme.black_text_disabled : props.theme.primary;

        default:
          return props.disabled ? props.theme.black_text_disabled : props.theme.primary;
      }
    }};
    box-shadow: ${(props) => {
      return props.colorTheme === 'primary' && !props.disabled ? props.theme.shadow_dept4 : 'none';
    }};
  }

  &:active {
    background-color: ${(props) => {
      switch (props.colorTheme) {
        case 'primary':
          return props.disabled ? props.theme.gray_500 : props.theme.primary_900;

        case 'link':
          return 'transparent';

        default:
          return rgba(props.theme.primary_700, 0.24);
      }
    }};

    color: ${(props) => {
      switch (props.colorTheme) {
        case 'primary':
          return props.disabled ? props.theme.white_text_disabled : props.theme.white;

        default:
          return props.disabled ? props.theme.black_text_disabled : props.theme.primary;
      }
    }};

    font-weight: ${(props) => {
      switch (props.colorTheme) {
        case 'link':
          return props.disabled ? 'normal' : '500';

        default:
          return 'normal';
      }
    }};

    box-shadow: ${(props) => {
      return props.colorTheme === 'primary' && !props.disabled ? props.theme.shadow_dept6 : undefined;
    }};
  }
`;

const button = (props) => {
  const disabled = props.disabled || props.isLoading;
  return (
    <Button disabled={disabled} {...props}>
      <Content>
        {props.icon && <MaterialIcon>{props.icon}</MaterialIcon>}
        <div>{props.children}</div>
        {props.isLoading && (
          <StyledSpinnerWrapper>
            <SpinnerInline color={props.spinnerColor} height={spinnerSizeMapper[props.size]}></SpinnerInline>
          </StyledSpinnerWrapper>
        )}
      </Content>
    </Button>
  );
};

export default button;
