import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  width: 100%;
  background-color: ${props => props.isModal ? 'transparent': 'white'};
  border: ${
    props => {
      if (props.isModal) {
        return 'none';
      } else {
        return `1px solid ${props.theme.border_line}`;
      }
    }
  };
  border-radius: ${
    props => {
      if (props.isModal) {
        return 0;
      }
      else {
        return '5px';
      }
    }
  };
`

const form = props => {
  return (
    <Form {...props}>
      {props.children}
    </Form>
  )
}

export default form;