import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow-x: visible;
    span {
      position: relative;
      z-index: 1;
      background-color: ${props => props.theme.light};
    }
  }
`

export default props => (
  <Wrapper {...props}>
    <span>
      {props.children}
    </span>
  </Wrapper>
);