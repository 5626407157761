import React, { Component } from 'react';
import styled from 'styled-components';
import Section from './Section';

const Wrapper = styled.div`
  height: ${(props) => props.height}px;
  overflow-y: scroll;
`;

class Accordion extends Component {
  static Section = Section;

  constructor(props) {
    super(props);
    const openSections = {};
    React.Children.map(this.props.children, (child) => {
      if (child.props.isOpen) {
        openSections[child.props.keyIndex] = true;
      }
    });
    this.state = { openSections };
  }

  onClick = (keyIndex) => {
    const { openSections } = this.state;
    const { allowMultipleOpen } = this.props;
    const isOpen = !!openSections[keyIndex];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [keyIndex]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [keyIndex]: !isOpen,
        },
      });
    }
  };

  render() {
    const { onClick } = this;
    const { children, height } = this.props;
    const { openSections } = this.state;
    const sectionMaxHeight = height - 48 * children.length;
    return (
      <Wrapper {...this.props}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            isOpen: !!openSections[child.props.keyIndex],
            onClick,
            maxHeight: sectionMaxHeight,
          });
        })}
      </Wrapper>
    );
  }
}

export default Accordion;
