import axios from '../axios';

// Actions

export const LOAD_ANALYTICS_REQUEST_LIST_INPROGRESS = 'LOAD_ANALYTICS_REQUEST_LIST_INPROGRESS';
export const LOAD_ANALYTICS_REQUEST_LIST_SUCCESS = 'LOAD_ANALYTICS_REQUEST_LIST_SUCCESS';
export const LOAD_ANALYTICS_REQUEST_LIST_FAILED = 'LOAD_ANALYTICS_REQUEST_LIST_FAILED';

export const CLEAR_ANALYTICS_REQUEST_LIST = 'CLEAR_ANALYTICS_REQUEST_LIST';

const initialState = {
  all: [],
  loading: false,
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ANALYTICS_REQUEST_LIST_INPROGRESS:
      return {
        ...state,
        all: [],
        loading: true,
      };

    case LOAD_ANALYTICS_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        all: [...state.all, ...action.data],
        loading: false,
      };

    case LOAD_ANALYTICS_REQUEST_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case CLEAR_ANALYTICS_REQUEST_LIST:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}

export const loadAnalyticsRequestListInProgress = () => {
  return {
    type: LOAD_ANALYTICS_REQUEST_LIST_INPROGRESS,
  };
};

export const loadAnalyticsRequestListSuccess = (data) => {
  return {
    type: LOAD_ANALYTICS_REQUEST_LIST_SUCCESS,
    data,
  };
};

export const loadAnalyticsRequestListFailed = (error) => {
  return {
    type: LOAD_ANALYTICS_REQUEST_LIST_FAILED,
    error,
  };
};

export const loadAnalyticsRequestList =
  ({ plotId, externalSystemCode }, fields = []) =>
  (dispatch) => {
    dispatch(loadAnalyticsRequestListInProgress());

    const includeFields = fields.length > 0 ? `user,${fields.join(',')}` : 'user';

    const codes = Array.isArray(externalSystemCode) ? externalSystemCode : [externalSystemCode];
    Promise.all(
      codes.map((c) =>
        axios.get('/analytics/requests', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          params: {
            includeFields,
            externalSystemCode: c,
            plotId,
          },
        })
      )
    )
      .then((response) => {
        dispatch(loadAnalyticsRequestListSuccess([].concat(...response.map((res) => res.data || []))));
      })
      .catch((error) => dispatch(loadAnalyticsRequestListFailed(error)));
  };

export const clearAnalyticsRequestList = () => {
  return {
    type: CLEAR_ANALYTICS_REQUEST_LIST,
  };
};
