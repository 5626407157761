import React, { Component } from 'react'
import styled from 'styled-components';
import BreadcrumbItem from './BreadcrumbItem';
import breadcrumbArrow from '../../../../assets/icon/breadcrumb-arrow.svg';

const Arrow = styled.img.attrs({ src: breadcrumbArrow })`
  margin: auto 8px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;  
`;

const BreadcrumbDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

class Breadcrumb extends Component {

  static Item = BreadcrumbItem;

  render() {
    const child = Array.isArray(this.props.children) ? this.props.children : [this.props.children];

    const pathComp = child.reduce((accu, elem, idx, src) => {
      accu.push(elem);
      if (idx !== src.length - 1)
        accu.push(<Arrow key={idx} />);
      return accu;
    }, []);

    return (
      <BreadcrumbDiv>
        {pathComp}
      </BreadcrumbDiv>
    );
  }
}

export default Breadcrumb;