import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';

import authReducer from './redux/auth';
import organizationReducer from './redux/organization';
import unitReducer from './redux/unit';
import dronemodelReducer from './redux/droneModel';
import dronemasterReducer from './redux/droneMaster';
import payloadtypeReducer from './redux/payloadtype';
import userReducer from './redux/user';
import chemicalReducer from './redux/chemical';
import droneReducer from './redux/drone';
import payloadReducer from './redux/payload';
import clientReducer from './redux/client';
import siteReducer from './redux/site';
import plotReducer from './redux/plot';
import obstacleReducer from './redux/obstacle';
import taskReducer from './redux/task';
import projectReducer from './redux/project';
import flightReducer from './redux/flight';
import flighteventsReducer from './redux/flightEvent';
import flighteventdataReducer from './redux/flightEventData';
import livelogReducer from './redux/liveLog';
import registrationReducer from './redux/registration';
import organizationsettingReducer from './redux/organizationSetting';
import externalSystemReducer from './redux/externalSystem';
import policyReducer from './redux/policy';
import analyticprocessingresultReducer from './redux/analyticProcessingResult';
import promptReducer from './redux/prompt';
import analyticprocessingrequestReducer from './redux/analyticProcessingRequest';
import subscriptionReducer from './redux/subscription';
import vehicleReducer from './redux/vehicle';
import vehicleModelReducer from './redux/vehicleModel';
import vehicleTypeReducer from './redux/vehicleType';
import referenceDataReducer from './redux/referenceData';
import routeReducer from './redux/route';
import plantReducer from './redux/plant';
import notificationReducer from './redux/notification';
import shareTaskReducer from './redux/shareTask';
const plotPersistConfig = {
  key: 'plot',
  storage: storage,
};

const createRootReducer = (asyncReducers) => {
  return combineReducers({
    auth: authReducer,
    organization: organizationReducer,
    dronemodel: dronemodelReducer,
    dronemaster: dronemasterReducer,
    payloadtype: payloadtypeReducer,
    unit: unitReducer,
    user: userReducer,
    chemical: chemicalReducer,
    drone: droneReducer,
    payload: payloadReducer,
    client: clientReducer,
    site: siteReducer,
    plot: persistReducer(plotPersistConfig, plotReducer),
    obstacle: obstacleReducer,
    task: taskReducer,
    project: projectReducer,
    flight: flightReducer,
    flightevent: flighteventsReducer,
    flighteventdata: flighteventdataReducer,
    livelog: livelogReducer,
    registration: registrationReducer,
    organizationsetting: organizationsettingReducer,
    externalSystem: externalSystemReducer,
    policy: policyReducer,
    analyticprocessingresult: analyticprocessingresultReducer,
    prompt: promptReducer,
    analyticprocessingrequest: analyticprocessingrequestReducer,
    subscription: subscriptionReducer,
    vehicleModel: vehicleModelReducer,
    vehicleType: vehicleTypeReducer,
    vehicle: vehicleReducer,
    referenceData: referenceDataReducer,
    route: routeReducer,
    plant: plantReducer,
    notification: notificationReducer,
    shareTask: shareTaskReducer,
    ...asyncReducers,
  });
};

const initStore = () => {
  const rootReducer = createRootReducer();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  const persistor = persistStore(store);
  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createRootReducer(store.asyncReducers));
    persistor.persist();
    return store;
  };
  return { store, persistor };
};

export default initStore;
