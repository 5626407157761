import axios from '../axios';

export const FLIGHT_ONLINE = 'FLIGHT_ONLINE';
export const UPDATE_RECENT_LOG_TIME = 'UPDATE_RECENT_LOG_TIME';
export const UPDATE_ONLINE_FLIGHT = 'UPDATE_ONLINE_FLIGHT';

const initialState = {
  flights: [],
  recentLogTime: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FLIGHT_ONLINE:
      return {
        ...state,
        flights: [...state.flights, action.data],
        recentLogTime: {
          ...state.recentLogTime,
          [action.data._id]: new Date(),
        },
      };
    case UPDATE_RECENT_LOG_TIME:
      return {
        ...state,
        recentLogTime: {
          ...state.recentLogTime,
          [action.flightId]: action.timestamp,
        },
      };
    case UPDATE_ONLINE_FLIGHT:
      return {
        ...state,
        flights: state.flights.filter((flight) => action.onlineFlight.includes(flight._id.toString())),
      };
    default:
      return state;
  }
}

export const flightOnline = (data) => {
  return {
    type: FLIGHT_ONLINE,
    data,
  };
};

export const updateOnlineFlight = (onlineFlight) => {
  return {
    type: UPDATE_ONLINE_FLIGHT,
    onlineFlight,
  };
};

export const updateRecentLogTime = (flightId, timestamp) => {
  return {
    type: UPDATE_RECENT_LOG_TIME,
    flightId,
    timestamp,
  };
};

export const flightTelemetryOnline = (data) => {
  return (dispatch, getState) => {
    axios
      .get(`/flights/${data.flightId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        const currentFlights = getState().livelog.flights;
        if (currentFlights.find((f) => f._id.toString() === response.data._id.toString())) {
          return;
        }

        dispatch(flightOnline(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
