import axios from '../axios';

// Actions
export const FILTER_DRONE_MASTER_LIST_SUCCESS = 'FILTER_DRONE_MASTER_LIST_SUCCESS';
export const FILTER_DRONE_MASTER_LIST_FAILED = 'FILTER_DRONE_MASTER_LIST_FAILED';
export const CLEAR_FILTER_DRONE_MASTER_LIST = 'CLEAR_FILTER_DRONE_MASTER_LIST';

// Reduces
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FILTER_DRONE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        filter: action.data,
        error: '',
      };
    case FILTER_DRONE_MASTER_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_FILTER_DRONE_MASTER_LIST:
      return {
        ...state,
        filter: [],
        error: '',
      };
    default:
      return state;
  }
}

// Action Creators
export const filterDroneMasterListSuccess = (data) => {
  return {
    type: FILTER_DRONE_MASTER_LIST_SUCCESS,
    data,
  };
};

export const filterDroneMasterListFailed = (error) => {
  return {
    type: FILTER_DRONE_MASTER_LIST_FAILED,
    error,
  };
};

export const filterDroneMasterList = (droneModelId) => {
  return (dispatch) => {
    axios
      .get(`/drone-masters?droneModelId=${droneModelId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(filterDroneMasterListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(filterDroneMasterListFailed(error));
      });
  };
};

export const clearFilterDroneMasterList = () => {
  return {
    type: CLEAR_FILTER_DRONE_MASTER_LIST,
  };
};
