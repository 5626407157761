import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../ui';
import useIntl from '../../../hooks/useIntl';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { clearShareTask, shareTask } from '../../../redux/shareTask';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

const TextContent = styled.div.attrs({ className: 'body1' })`
  color: ${(props) => props.theme.black};
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 20px;
  font-size: 19px;
`;

const CodeContent = styled.div.attrs({ className: 'body1' })`
  color: ${(props) => props.theme.black};
  text-transform: capitalize;
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 20px;
  font-size: 45px;
`;

const TaskCodeGenerator = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, setLanguage } = useIntl(['share-task']);
  const { language, taskId, accessToken } = useParams();
  const decodedAccessToken = decodeURIComponent(accessToken);
  const generateCode = useSelector((state) => state.shareTask?.generateCode?.activationCode);
  const generateCodeError = useSelector((state) => state.shareTask?.error);

  const handleLanguage = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    handleLanguage(language);
    dispatch(shareTask(taskId, decodedAccessToken));

    return () => {
      dispatch(clearShareTask());
    };
    // eslint-disable-next-line
  }, [dispatch, language, taskId, decodedAccessToken]);

  const handleResentShareCode = () => {
    dispatch(shareTask(taskId, decodedAccessToken));
  };

  const handleCloseTab = () => {
    const android = window.Android;
    if (android) {
      android.closeWindowTaskCodeGenerator();
    } else {
      history.push('/');
    }
  };

  return (
    <Modal
      id='task-code-generator-modal'
      title={t('share-task:modal_task_code_generator.title')}
      visible={true}
      disabledOverlay={true}
      backgroundColor='rgba(0, 0, 0, 0.09)'
      okText={t('share-task:modal_task_code_generator.confirm_button')}
      onOk={handleCloseTab}
      okDisabled={generateCodeError}
      modalFooterAlign='center'
      cancelText={t('share-task:modal_task_code_generator.cancel_button')}
      onCancel={handleResentShareCode}
      cancleDisabled={generateCodeError}>
      {isEmpty(generateCodeError) ? (
        <>
          <TextContent>{t('share-task:modal_task_code_generator.content.text_share')}</TextContent>
          <CodeContent>{generateCode}</CodeContent>
        </>
      ) : (
        <TextContent>{t('share-task:modal_task_code_generator.error')}</TextContent>
      )}
    </Modal>
  );
};

export default TaskCodeGenerator;
