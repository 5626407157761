import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_USER_LIST = 'LOAD_USER_LIST';
export const LOAD_USER_LIST_SUCCESS = 'LOAD_LIST_USER_SUCCESS';
export const LOAD_USER_LIST_FAILED = 'LOAD_LIST_USER_FAILED';

export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';

export const EDIT_USER = 'EDIT_USER';
export const INIT_SAVE_USER = 'INIT_SAVE_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';

export const NEW_USER = 'NEW_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';
export const CLOSE_USER_FORM = 'CLOSE_USER_FORM';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSOWRD_FAILED = 'RESET_PASSOWRD_FAILED';

const initialState = {
  all: [],
  selected: undefined,
  isSaving: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USER_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };

    case LOAD_USER_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CLEAR_SELECTED_USER:
      return {
        ...state,
        selected: {},
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        selected: action.data,
        isPasswordReset: false,
      };

    case LOAD_USER_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case NEW_USER:
      return {
        ...state,
        form: {
          isNew: true,
          isActive: true,
        },
      };

    case UPDATE_USER_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };

    case EDIT_USER:
      return {
        ...state,
        form: {
          ...state.selected,
        },
      };

    case INIT_SAVE_USER:
      return {
        ...state,
        isSaving: true,
        error: null,
      };

    case SAVE_USER_SUCCESS:
      return {
        ...state,
        selected: action.data,
        form: undefined,
        error: null,
        isSaving: false,
      };

    case SAVE_USER_FAILED:
      return {
        ...state,
        error: action.error,
        isSaving: false,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
        error: null,
        isSaving: false,
      };

    case CREATE_USER_FAILED:
      return {
        ...state,
        error: action.error,
        isSaving: false,
      };

    case CLOSE_USER_FORM:
      return {
        ...state,
        form: undefined,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          error: null,
          isPasswordReset: true,
          newPassword: action.newPassword,
        },
      };

    default:
      return state;
  }
}

// Action Creators
export const loadUserListSuccess = (data) => {
  return {
    type: LOAD_USER_LIST_SUCCESS,
    data,
  };
};

export const loadUserListFailed = (error) => {
  return {
    type: LOAD_USER_LIST_FAILED,
    error,
  };
};

export const loadUserList = () => {
  return (dispatch) => {
    axios
      .get('/users', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadUserListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadUserListFailed(error));
      });
  };
};

export const loadUserSuccess = (data) => {
  return {
    type: LOAD_USER_SUCCESS,
    data,
  };
};

export const loadUserFailed = (error) => {
  return {
    type: LOAD_USER_FAILED,
    error,
  };
};

export const loadUser = (id) => {
  return (dispatch) => {
    axios
      .get(`/users/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadUserFailed(error));
      });
  };
};

export const newUser = () => {
  return {
    type: NEW_USER,
  };
};

export const updateUserProperty = (data) => {
  return {
    type: UPDATE_USER_PROPERTY,
    data,
  };
};

export const saveUserSuccess = (data) => {
  notification['success']({
    message: i18next.t('user:control.notification.save_user_info_success_message.message', 'Saved!'),
    description: i18next.t('user:control.notification.save_user_info_success_message.description', 'Your settings have been updated.'),
    className: 'sub2',
  });
  return {
    type: SAVE_USER_SUCCESS,
    data,
  };
};

export const saveUserFailed = (error) => {
  if (error.response.data.code === 409) {
    notification['error']({
      message: i18next.t('user:control.notification.create_user_info_failed_message.message'),
      description: i18next.t('user:control.notification.create_user_info_failed_message.user_duplicated_description'),
      className: 'sub2',
    });
  }

  return {
    type: SAVE_USER_FAILED,
    error,
  };
};

export const saveUser = (id, data) => {
  return (dispatch) => {
    dispatch(initSaveUser());

    axios
      .patch(`/users/${id}`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveUserFailed(error));
      });
  };
};

export const editUser = () => {
  return {
    type: EDIT_USER,
  };
};

export const initSaveUser = () => {
  return {
    type: INIT_SAVE_USER,
  };
};

export const createUserSuccess = (data) => {
  notification['success']({
    message: i18next.t('user:control.notification.create_user_info_success_message.message'),
    description: i18next.t('user:control.notification.create_user_info_success_message.description'),
    className: 'sub2',
  });
  return {
    type: CREATE_USER_SUCCESS,
    data,
  };
};

export const createUserFailed = (error) => {
  if (error.response.data.code === 409) {
    notification['error']({
      message: i18next.t('user:control.notification.create_user_info_failed_message.message'),
      description: i18next.t('user:control.notification.create_user_info_failed_message.user_duplicated_description'),
      className: 'sub2',
    });
  }
  return {
    type: CREATE_USER_FAILED,
    error,
  };
};

export const createUser = (data, history) => {
  return (dispatch) => {
    dispatch(initSaveUser());
    axios
      .post(`/users`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        history.replace('/settings/members');
      })
      .catch((error) => {
        dispatch(createUserFailed(error));
      });
  };
};

export const changePassword = (data, disableRedirectToChangePassword, extraDelayMS = 0) => {
  axios
    .post('/changepassword', { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
    .then((response) => {
      setTimeout(() => {
        notification['success']({
          message: i18next.t('user:control.notification.change_password_success_message.message', 'Password changed successfully'),
        });
      }, extraDelayMS);
      disableRedirectToChangePassword();
    })
    .catch((error) => {
      notification['error']({
        message: i18next.t('user:control.notification.change_password_failed_message.message', 'Change Password Failed'),
        description: error.response.data.message,
      });
    });
};

export const resetPasswordSuccess = (newPassword) => {
  notification['success']({
    message: i18next.t('user:control.notification.reset_password_success_message.message'),
    description: i18next.t('user:control.notification.reset_password_success_message.description'),
  });

  return {
    type: RESET_PASSWORD_SUCCESS,
    newPassword,
  };
};

export const resetPasswordFailed = (error) => {
  notification['error']({
    message: i18next.t('user:control.notification.reset_password_failed_message.message'),
    description: error.response.data.message,
  });

  return {
    type: RESET_PASSOWRD_FAILED,
    error,
  };
};

export const resetPassword = (id) => {
  return (dispatch) => {
    axios
      .post(
        '/resetpassword/',
        { id, isRandomPasswordMode: true },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      )
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data.newPassword));
      })
      .catch((error) => {
        dispatch(resetPasswordFailed(error));
      });
  };
};

export const closeUserForm = () => {
  return {
    type: CLOSE_USER_FORM,
  };
};

export const clearSelectedUser = () => {
  return {
    type: CLEAR_SELECTED_USER,
  };
};
