import axios from '../axios';

// Actions
export const LOAD_PLANT_LIST = 'LOAD_PLANT_LIST';
export const LOAD_PLANT_LIST_SUCCESS = 'LOAD_PLANT_LIST_SUCCESS';
export const LOAD_PLANT_LIST_FAILED = 'LOAD_PLANT_LIST_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_PLANT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_PLANT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
export const loadPlantListSuccess = (data) => {
  return {
    type: LOAD_PLANT_LIST_SUCCESS,
    data,
  };
};

export const loadPlantListFailed = (error) => {
  return {
    type: LOAD_PLANT_LIST_FAILED,
    error,
  };
};

export const loadPlantList = () => {
  return (dispatch) => {
    axios
      .get('/plants', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadPlantListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPlantListFailed(error));
      });
  };
};
