import { keyBy } from 'lodash';
import axios from '../axios';

// Actions
export const GET_REFERENCE_DATA_SUCCESS = 'GET_REFERENCE_DATA_SUCCESS';
export const GET_REFERENCE_DATA_FAILED = 'GET_REFERENCE_DATA_FAILED';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case GET_REFERENCE_DATA_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getReferenceData = (params) => {
  return (dispatch) => {
    axios
      .get(`/reference-data`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        params,
      })
      .then((response) => {
        dispatch({
          type: GET_REFERENCE_DATA_SUCCESS,
          data: keyBy(response.data, 'code'),
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_REFERENCE_DATA_FAILED,
          error,
        });
      });
  };
};
