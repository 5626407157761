import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';
import { ERROR } from '../enums/projectValidation';

// Actions
export const NEW_PROJECT = 'NEW_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILED = 'UPDATE_PROJECT_FAILED';
export const CREATE_PROJECT_SURVEY_PERIOD_SUCCESS = 'CREATE_PROJECT_SURVEY_PERIOD_SUCCESS';
export const UPDATE_PROJECT_SURVEY_PERIOD_SUCCESS = 'UPDATE_PROJECT_SURVEY_PERIOD_SUCCESS';
export const UPDATE_PROJECT_PROPERTY = 'UPDATE_PROJECT_PROPERTY';
export const UPDATE_SURVEY_PERIOD_PROPERTY = 'UPDATE_SURVEY_PERIOD_PROPERTY';
export const LOAD_PROJECT_LIST_IN_PROGRESS = 'LOAD_PROJECT_LIST_IN_PROGRESS';
export const LOAD_PROJECT_LIST_SUCCESS = 'LOAD_PROJECT_LIST_SUCCESS';
export const LOAD_PROJECT_LIST_FAILED = 'LOAD_PROJECT_LIST_FAILED';
export const LOAD_PROJECT_TYPE_LIST_IN_PROGRESS = 'LOAD_PROJECT_TYPE_LIST_IN_PROGRESS';
export const LOAD_PROJECT_TYPE_LIST_SUCCESS = 'LOAD_PROJECT_TYPE_LIST_SUCCESS';
export const LOAD_PROJECT_TYPE_LIST_FAILED = 'LOAD_PROJECT_TYPE_LIST_FAILED';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAILED = 'LOAD_PROJECT_FAILED';
export const CLEAR_PROJECT_PROPERTY = 'CLEAR_PROJECT_PROPERTY';
export const LOAD_PROJECT_SURVEY_PERIOD_LIST_IN_PROGRESS = 'LOAD_PROJECT_SURVEY_PERIOD_LIST_IN_PROGRESS';
export const LOAD_PROJECT_SURVEY_PERIOD_LIST_SUCCESS = 'LOAD_PROJECT_SURVEY_PERIOD_LIST_SUCCESS';
export const LOAD_PROJECT_SURVEY_PERIOD_LIST_FAILED = 'LOAD_PROJECT_SURVEY_PERIOD_LIST_FAILED';
export const CLEAR_PROJECT_ERROR = 'CLEAR_PROJECT_ERROR';

const initialState = {
  all: [],
  form: {
    surveyList: [],
  },
  surveyPeriodForm: {},
  loadedSurveyList: [],
  types: [],
  isLoading: false,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        error: undefined,
      };

    case CREATE_PROJECT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: undefined,
      };

    case UPDATE_PROJECT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CREATE_PROJECT_SURVEY_PERIOD_SUCCESS:
      return state;

    case UPDATE_PROJECT_SURVEY_PERIOD_SUCCESS:
      return state;

    case UPDATE_PROJECT_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
        error: undefined,
      };

    case UPDATE_SURVEY_PERIOD_PROPERTY:
      return {
        ...state,
        surveyPeriodForm: {
          ...state.surveyPeriodForm,
          ...action.data,
        },
      };

    case LOAD_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
        isLoading: false,
      };

    case LOAD_PROJECT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case LOAD_PROJECT_LIST_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_PROJECT_SURVEY_PERIOD_LIST_SUCCESS:
      return {
        ...state,
        loadedSurveyList: action.data,
        isLoading: false,
      };

    case LOAD_PROJECT_SURVEY_PERIOD_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case LOAD_PROJECT_SURVEY_PERIOD_LIST_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_PROJECT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        types: action.data,
        isLoading: false,
      };

    case LOAD_PROJECT_TYPE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case LOAD_PROJECT_TYPE_LIST_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
        error: undefined,
      };

    case LOAD_PROJECT_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case CLEAR_PROJECT_PROPERTY:
      return {
        ...state,
        form: {
          surveyList: [],
        },
        loadedSurveyList: [],
        error: undefined,
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        error: undefined,
      };

    default:
      return state;
  }
}

// Validator
const validateProject = ({ name, code, startDate, solutionId, type }) => {
  if (!code || code === '') {
    return ERROR.CODE_REQUIRED;
  }
  if (!name || name.trim() === '') {
    return ERROR.NAME_REQUIRED;
  }
  if (!startDate || startDate === '') {
    return ERROR.START_DATE_REQUIRED;
  }
  if (!solutionId || solutionId === '') {
    return ERROR.SOLUTION_REQUIRED;
  }
  if (!type || type === 0) {
    return ERROR.TYPE_REQUIRED;
  }

  return;
};

const validateProjectSurveyPeriod = ({ surveyList }) => {
  if (!surveyList || surveyList.length <= 0) {
    return ERROR.SURVEY_LIST_REQUIRED;
  }

  return;
};

// Action
export const updateProjectProperty = (data) => {
  return {
    type: UPDATE_PROJECT_PROPERTY,
    data,
  };
};

export const updateSurveyPeriodProperty = (data) => {
  return {
    type: UPDATE_SURVEY_PERIOD_PROPERTY,
    data,
  };
};

export const createProjectSurveyPeriod = (projectId, surveyList) => {
  return async (dispatch) => {
    const error = validateProjectSurveyPeriod({ surveyList });
    if (error) {
      dispatch(createProjectSurveyPeriodsFailed(error));
      return;
    }

    for (const survey of surveyList) {
      await axios
        .post(
          `/projects/${projectId}/surveyperiods`,
          {
            code: survey.surveyCode,
            startDate: survey.surveyStartDate,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        )
        .catch((error) => {
          dispatch(createProjectSurveyPeriodsFailed(error));
        });
    }
    dispatch(createProjectSurveyPeriodsSuccess());
  };
};

export const createProjectSurveyPeriodsSuccess = () => {
  notification['success']({
    message: i18next.t('project:control.notification.create_project_survey_period_success_message.message', 'Successful!'),
    description: i18next.t(
      'project:control.notification.create_project_survey_period_success_message.description',
      'New Project Survey Period has been created.'
    ),
    className: 'sub2',
  });
  return {
    type: CREATE_PROJECT_SURVEY_PERIOD_SUCCESS,
  };
};

export const createProjectSurveyPeriodsFailed = (error) => {
  return {
    type: CREATE_PROJECT_FAILED,
    error,
  };
};

export const updateProjectSurveyPeriod = (projectId, surveyList, history) => {
  return async (dispatch, getState) => {
    const error = validateProjectSurveyPeriod({ surveyList });
    if (error) {
      dispatch(updateProjectSurveyPeriodsFailed(error));
      return;
    }

    // create new project-survey-periods
    for (const survey of surveyList) {
      if (survey && !survey._id) {
        await axios
          .post(
            `/projects/${projectId}/surveyperiods`,
            {
              code: survey.surveyCode,
              startDate: survey.surveyStartDate,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          )
          .catch((error) => {
            dispatch(updateProjectSurveyPeriodsFailed(error));
          });
      } else if (survey && survey._id) {
        await axios
          .patch(
            `/projects/${projectId}/surveyperiods/${survey._id}`,
            {
              code: survey.surveyCode,
              startDate: survey.surveyStartDate,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          )
          .catch((error) => {
            dispatch(updateProjectSurveyPeriodsFailed(error));
          });
      }
    }

    // delete project-survey-periods
    for (const loadedSurvey of getState().project.loadedSurveyList) {
      const survey = surveyList.find((e) => e._id === loadedSurvey._id);

      if (!survey) {
        await axios
          .delete(`/projects/${projectId}/surveyperiods/${loadedSurvey._id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .catch((error) => {
            dispatch(updateProjectSurveyPeriodsFailed(error));
          });
      }
    }
    dispatch(updateProjectSurveyPeriodsSuccess(history));
  };
};

export const updateProjectSurveyPeriodsSuccess = (history) => {
  notification['success']({
    message: i18next.t('project:control.notification.save_project_survey_period_success_message.message', 'Successful!'),
    description: i18next.t(
      'project:control.notification.save_project_survey_period_success_message.description',
      'The Project Survey Period has been updated.'
    ),
    className: 'sub2',
  });
  history.push('.');

  return {
    type: UPDATE_PROJECT_SURVEY_PERIOD_SUCCESS,
  };
};

export const updateProjectSurveyPeriodsFailed = (error) => {
  return {
    type: UPDATE_PROJECT_FAILED,
    error,
  };
};

export const updateProject = (projectId, history) => {
  return (dispatch, getState) => {
    const projectForm = getState().project.form;

    const projectError = validateProject({
      name: projectForm.name,
      code: projectForm.code,
      startDate: projectForm.startDate,
      solutionId: projectForm.solutionId,
      type: projectForm.type.length,
    });
    if (projectError) {
      dispatch(updateProjectFailed(projectError));
      return;
    }

    const surveyPeriodError = validateProjectSurveyPeriod({
      surveyList: projectForm.surveyList,
    });
    if (surveyPeriodError) {
      dispatch(updateProjectSurveyPeriodsFailed(surveyPeriodError));
      return;
    }

    let requestForm = {
      ...projectForm,
      startDate: projectForm.startDate,
    };
    delete requestForm.surveyList;

    axios
      .patch(`/projects/${projectId}`, requestForm, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(updateProjectSuccess(response.data, projectForm.surveyList, history));
      })
      .catch((error) => {
        if (error.response.status === 494) {
          dispatch(updateProjectFailed(ERROR.CLIENT_REQUIRED));
        } else {
          dispatch(updateProjectFailed(error));
        }
      });
  };
};

export const updateProjectSuccess = (data, surveyList, history) => {
  notification['success']({
    message: i18next.t('project:control.notification.save_project_success_message.message', 'Successful!'),
    description: i18next.t('project:control.notification.save_project_success_message.description', 'The Project has been updated.'),
    className: 'sub2',
  });
  return (dispatch) => {
    dispatch(updateProjectSurveyPeriod(data._id, surveyList, history));
    return {
      type: UPDATE_PROJECT_SUCCESS,
      data,
    };
  };
};

export const updateProjectFailed = (error) => {
  return {
    type: UPDATE_PROJECT_FAILED,
    error,
  };
};

export const createProject = (history) => {
  return (dispatch, getState) => {
    const projectForm = getState().project.form;

    const error = validateProject({
      name: projectForm.name,
      code: projectForm.code,
      startDate: projectForm.startDate,
      solutionId: projectForm.solutionId,
      type: projectForm.type?.length,
    });

    if (error) {
      dispatch(createProjectFailed(error));
      return;
    }

    const surveyPeriodError = validateProjectSurveyPeriod({
      surveyList: projectForm.surveyList,
    });
    if (surveyPeriodError) {
      dispatch(createProjectSurveyPeriodsFailed(surveyPeriodError));
      return;
    }

    let requestForm = {
      ...projectForm,
    };
    delete requestForm.surveyList;

    axios
      .post('/projects', requestForm, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        dispatch(createProjectSuccess(response.data, projectForm.surveyList));
        history.replace('/projects');
      })
      .catch((error) => {
        if (error.response.status === 494) {
          dispatch(createProjectFailed(ERROR.CLIENT_REQUIRED));
        } else {
          dispatch(createProjectFailed(error));
        }
      });
  };
};

export const createProjectSuccess = (data, surveyList) => {
  notification['success']({
    message: i18next.t('project:control.notification.create_project_success_message.message', 'Successful!'),
    description: i18next.t('project:control.notification.create_project_success_message.description', 'New Project has been created.'),
    className: 'sub2',
  });
  return (dispatch) => {
    dispatch(createProjectSurveyPeriod(data._id, surveyList));
    return {
      type: CREATE_PROJECT_SUCCESS,
      data,
    };
  };
};

export const createProjectFailed = (error) => {
  return {
    type: CREATE_PROJECT_FAILED,
    error,
  };
};

export const loadProjectList = ({ clientIds, externalSystemCode }) => {
  return (dispatch) => {
    dispatch(loadProjectListInProgress());
    const codes = externalSystemCode ? (Array.isArray(externalSystemCode) ? externalSystemCode : [externalSystemCode]) : [];
    axios
      .get('/projects', {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        params: {
          externalSystemCode: codes,
          clientIds,
        },
      })
      .then((response) => {
        dispatch(loadProjectListSuccess(response.data));
      })
      .catch((error) => dispatch(loadProjectListFailed(error)));
  };
};

export const loadProjectListInProgress = () => {
  return {
    type: LOAD_PROJECT_LIST_IN_PROGRESS,
  };
};

export const loadProjectListSuccess = (data) => {
  return {
    type: LOAD_PROJECT_LIST_SUCCESS,
    data,
  };
};

export const loadProjectListFailed = (error) => {
  return {
    type: LOAD_PROJECT_LIST_FAILED,
    error,
  };
};

export const loadProjectSurveyPeriodList = (projectId) => {
  return (dispatch) => {
    dispatch(loadProjectSurveyPeriodListInProgress());
    if (projectId) {
      axios
        .get(`/projects/${projectId}/surveyperiods`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          dispatch(loadProjectSurveyPeriodListSuccess(response.data));
        })
        .catch((error) => {
          dispatch(loadProjectSurveyPeriodListFailed(error));
        });
    } else {
      dispatch(loadProjectSurveyPeriodListSuccess([]));
    }
  };
};

export const loadProjectSurveyPeriodListInProgress = () => {
  return {
    type: LOAD_PROJECT_SURVEY_PERIOD_LIST_IN_PROGRESS,
  };
};

export const loadProjectSurveyPeriodListSuccess = (data) => {
  return {
    type: LOAD_PROJECT_SURVEY_PERIOD_LIST_SUCCESS,
    data,
  };
};

export const loadProjectSurveyPeriodListFailed = (error) => {
  return {
    type: LOAD_PROJECT_SURVEY_PERIOD_LIST_FAILED,
    error,
  };
};

export const loadProjectTypeListInProgress = () => {
  return {
    type: LOAD_PROJECT_LIST_IN_PROGRESS,
  };
};

export const loadProjectTypeList = () => {
  return (dispatch) => {
    dispatch(loadProjectTypeListInProgress());
    axios
      .get('/projecttypes', {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadProjectTypeListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadProjectTypeListFailed(error));
      });
  };
};

export const loadProjectTypeListSuccess = (data) => {
  return {
    type: LOAD_PROJECT_TYPE_LIST_SUCCESS,
    data,
  };
};

export const loadProjectTypeListFailed = (error) => {
  return {
    type: LOAD_PROJECT_TYPE_LIST_FAILED,
    error,
  };
};

export const loadProject = (id) => {
  return (dispatch) => {
    axios
      .get(`/projects/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        dispatch(loadProjectSuccess(response.data));
        dispatch(updateProjectProperty({ externalSystemCode: response.data.type.map((item) => item.split('-')[0]) }));
      })
      .catch((error) => {
        dispatch(loadProjectFailed(error));
      });
  };
};

export const loadProjectSuccess = (data) => {
  return {
    type: LOAD_PROJECT_SUCCESS,
    data,
  };
};

export const loadProjectFailed = (error) => {
  return {
    type: LOAD_PROJECT_FAILED,
    error,
  };
};

export const clearProjectProperty = () => {
  return {
    type: CLEAR_PROJECT_PROPERTY,
  };
};

export const clearProjectError = () => {
  return {
    type: CLEAR_PROJECT_ERROR,
  };
};
