import axios from '../axios';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const NEW_CHEMICAL = 'NEW_CHEMICAL';

export const LOAD_CHEMICAL_LIST_SUCCESS = 'LOAD_CHEMICAL_LIST_SUCCESS';
export const LOAD_CHEMICAL_LIST_FAILED = 'LOAD_CHEMICAL_LIST_FAILED';

export const UPDATE_CHEMICAL_PROPERTY = 'UPDATE_CHEMICAL_PROPERTY';
export const CLOSE_CHEMICAL_FORM = 'CLOSE_CHEMICAL_FORM';

export const EDIT_CHEMICAL = 'EDIT_CHEMICAL';
export const SAVE_CHEMICAL_SUCCESS = 'SAVE_CHEMICAL_SUCCESS';
export const SAVE_CHEMICAL_FAILED = 'SAVE_CHEMICAL_FAILED';

export const CREATE_CHEMICAL = 'CREATE_CHEMICAL';
export const CREATE_CHEMICAL_SUCCESS = 'CREATE_CHEMICAL_SUCCESS';
export const CREATE_CHEMICAL_FAILED = 'CREATE_CHEMICAL_FAILED';

const initialState = {
  all: [],
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_CHEMICAL:
      return {
        ...state,
        form: {
          isNew: true,
          isActive: true,
        },
      };
    case LOAD_CHEMICAL_LIST_SUCCESS:
      return {
        ...state,
        all: action.data,
      };
    case LOAD_CHEMICAL_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_CHEMICAL:
      return {
        ...state,
        form: state.all.find((x) => x._id.toString() === action.id.toString()),
      };
    case UPDATE_CHEMICAL_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case CLOSE_CHEMICAL_FORM:
      return {
        ...state,
        form: undefined,
      };
    case SAVE_CHEMICAL_SUCCESS:
      return {
        ...state,
        all: state.all.map((x) => {
          if (x._id.toString() === action.data._id.toString()) {
            return action.data;
          }
          return x;
        }),
        form: undefined,
      };
    case SAVE_CHEMICAL_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CREATE_CHEMICAL_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data],
        form: undefined,
      };
    case CREATE_CHEMICAL_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
export const newChemical = () => {
  return {
    type: NEW_CHEMICAL,
  };
};

export const loadChemicalListSuccess = (data) => {
  return {
    type: LOAD_CHEMICAL_LIST_SUCCESS,
    data,
  };
};

export const loadChemicalListFailed = (error) => {
  return {
    type: LOAD_CHEMICAL_LIST_FAILED,
    error,
  };
};

export const loadChemicalList = () => {
  return (dispatch) => {
    axios
      .get('/chemicals', { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadChemicalListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadChemicalListFailed(error));
      });
  };
};

export const editChemical = (id) => {
  return {
    type: EDIT_CHEMICAL,
    id,
  };
};

// Update
export const updateChemicalProperty = (data) => {
  return {
    type: UPDATE_CHEMICAL_PROPERTY,
    data,
  };
};

export const closeChemicalForm = () => {
  return {
    type: CLOSE_CHEMICAL_FORM,
  };
};

export const saveChemicalSuccess = (data) => {
  notification['success']({
    message: i18next.t('chemical:control.notification.save_success_message.message', 'Saved!'),
    description: i18next.t('chemical:control.notification.save_success_message.description', 'Your settings have been updated.'),
    className: 'sub2',
  });
  return {
    type: SAVE_CHEMICAL_SUCCESS,
    data,
  };
};

export const saveChemicalFailed = (error) => {
  notification['error']({
    message: i18next.t('chemical:control.notification.save_failed_message.message', 'Error!'),
    description: i18next.t('chemical:control.notification.save_failed_message.description', 'There is something wrong, please try again'),
    className: 'sub2',
  });
  return {
    type: SAVE_CHEMICAL_FAILED,
    error,
  };
};

export const saveChemical = (id, data) => {
  return (dispatch) => {
    axios
      .patch(`/chemicals/${id}`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveChemicalSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveChemicalFailed(error));
      });
  };
};

// Create
export const createChemicalSuccess = (data) => {
  notification['success']({
    message: i18next.t('chemical:control.notification.create_success_message.message', 'Successful!'),
    description: i18next.t('chemical:control.notification.create_success_message.description', 'New chemical has been added.'),
    className: 'sub2',
  });
  return {
    type: CREATE_CHEMICAL_SUCCESS,
    data,
  };
};

export const createChemicalFailed = (error) => {
  notification['error']({
    message: i18next.t('chemical:control.notification.create_failed_message.message', 'Error!'),
    description: i18next.t('chemical:control.notification.create_failed_message.description', 'There is something wrong, please try again'),
    className: 'sub2',
  });
  return {
    type: CREATE_CHEMICAL_FAILED,
    error,
  };
};

export const createChemical = (data) => {
  return (dispatch) => {
    axios
      .post(`/chemicals`, { ...data }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(createChemicalSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createChemicalFailed(error));
      });
  };
};
