import { Component } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { compose } from 'recompose';
import withIntl from './hoc/withIntl';
import { get } from 'lodash';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3030/api/v3',
  headers: {
    'Content-Type': 'application/json',
  },
});

class AxiosErrorHandler extends Component {
  componentDidMount() {
    const { t, i18n } = this.props;

    //Response interceptor
    instance.interceptors.response.use(
      function (response) {
        return response;
      },

      function (error) {
          if (get(error, 'response.status') === 680) {
          const data = error.response.data.data;
          const lang = i18n.language;

          notification['error']({
            message: t('system:rule.notification.subscription_litmit_reached'),
            description: data.translation && data.translation.errorText[lang],
            className: 'sub2',
          });
          error.handled = true;
        }

        if (get(error, 'response.status') === 402) {
          notification['error']({
            message: t('system:white_list.notification.message'),
            description: t('system:white_list.notification.description'),
            className: 'sub2',
          });
          error.handled = true;
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    return this.props.children;
  }
}

export const AxiosErrorHandlerComp = compose(withIntl(['system']))(AxiosErrorHandler);

export default instance;
