import axios from '../axios';
import Collator from '../collator';
import { notification } from 'antd';
import i18next from '../i18n';

// Actions
export const LOAD_CLIENT_LIST_SUCCESS = 'LOAD_CLIENT_LIST_SUCCESS';
export const LOAD_CLIENT_LIST_FAILED = 'LOAD_CLIENT_LIST_FAILED';

export const LOAD_SITE_LIST_SUCCESS = 'LOAD_SITE_LIST_SUCCESS';
export const LOAD_SITE_LIST_FAILED = 'LOAD_SITE_LIST_FAILED';

export const CREATE_DEFAULT_SITE_LIST_SUCCESS = 'CREATE_DEFAULT_SITE_LIST_SUCCESS';
export const CREATE_DEFAULT_SITE_LIST_FAILED = 'CREATE_DEFAULT_SITE_LIST_FAILED';

export const SHARE_TASK_SUCCESS = 'SHARE_TASK_SUCCESS';
export const SHARE_TASK_FAILED = 'SHARE_TASK_FAILED';

export const ACTIVATED_SHARE_TASK_SUCCESS = 'ACTIVATED_SHARE_TASK_SUCCESS';
export const ACTIVATED_SHARE_TASK_FAILED = 'ACTIVATED_SHARE_TASK_FAILED';

export const CLEAR_SHARE_TASK = 'CLEAR_SHARE_TASK';

const initialState = {};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        client: action.data,
      };
    case LOAD_CLIENT_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case LOAD_SITE_LIST_SUCCESS:
      return {
        ...state,
        site: action.data,
      };
    case LOAD_SITE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CREATE_DEFAULT_SITE_LIST_SUCCESS:
      return {
        ...state,
        site: action.data,
      };
    case CREATE_DEFAULT_SITE_LIST_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case SHARE_TASK_SUCCESS:
      return {
        ...state,
        generateCode: action.data,
      };
    case SHARE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case ACTIVATED_SHARE_TASK_SUCCESS:
      return {
        ...state,
        activationCode: action.data,
      };
    case ACTIVATED_SHARE_TASK_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SHARE_TASK:
      return {
        ...state,
        generateCode: undefined,
        activationCode: undefined,
        site: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}

export const loadClientListSuccess = (data) => {
  return {
    type: LOAD_CLIENT_LIST_SUCCESS,
    data,
  };
};

export const loadClientListFailed = (error) => {
  return {
    type: LOAD_CLIENT_LIST_FAILED,
    error,
  };
};

export const loadSiteListSuccess = (data) => {
  return {
    type: LOAD_SITE_LIST_SUCCESS,
    data,
  };
};

export const loadSiteListFailed = (error) => {
  return {
    type: LOAD_SITE_LIST_FAILED,
    error,
  };
};

export const createDefaultSiteSuccess = (data) => {
  return {
    type: CREATE_DEFAULT_SITE_LIST_SUCCESS,
    data,
  };
};

export const createDefaultSiteFailed = (error) => {
  return {
    type: CREATE_DEFAULT_SITE_LIST_FAILED,
    error,
  };
};

export const shareTaskSuccess = (data) => {
  return {
    type: SHARE_TASK_SUCCESS,
    data,
  };
};

export const shareTaskFailed = (error) => {
  return {
    type: SHARE_TASK_FAILED,
    error,
  };
};

export const activatedShareTaskSuccess = (data) => {
  notification['success']({
    message: i18next.t('share-task:modal_task_code_activation.notification.activated_share_task_success_message.message'),
    description: i18next.t('share-task:modal_task_code_activation.notification.activated_share_task_success_message.description'),
    className: 'sub2',
  });
  return {
    type: ACTIVATED_SHARE_TASK_SUCCESS,
    data,
  };
};

export const activatedShareTaskFailed = (error) => {
  const errorCode = error?.response?.status;
  if (errorCode >= 500 || !errorCode) {
    notification['error']({
      message: i18next.t('share-task:modal_task_code_activation.notification.activated_share_task_failed_message.message'),
      description: i18next.t('share-task:modal_task_code_activation.notification.activated_share_task_failed_message.description'),
      className: 'sub2',
    });
  }
  return {
    type: ACTIVATED_SHARE_TASK_FAILED,
    error: error.response,
  };
};

export const clearShareTask = () => {
  return {
    type: CLEAR_SHARE_TASK,
  };
};

export const loadClientList = (accessToken) => {
  return (dispatch) => {
    axios
      .get('/clients', { headers: { 'encrypted-token': true, Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadClientListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadClientListFailed(error));
      });
  };
};

export const loadSiteList = (clientId, accessToken) => {
  return (dispatch) => {
    axios
      .get(`/clients/${clientId}/sites`, { headers: { 'encrypted-token': true, Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        const data = response.data.sort((a, b) => Collator.compare(a.name, b.name));
        dispatch(loadSiteListSuccess(data));
      })
      .catch((error) => {
        dispatch(loadSiteListFailed(error));
      });
  };
};

export const createDefaultSite = (clientId, accessToken) => {
  return (dispatch) => {
    axios
      .patch(
        `/clients/${clientId}?defaultSite=true`,
        {
          defaultSite: {
            location: {
              lat: 15.870032,
              lng: 100.992541,
            },
          },
        },
        { headers: { 'encrypted-token': true, Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        dispatch(createDefaultSiteSuccess([response.data.site]));
      })
      .catch((error) => {
        dispatch(createDefaultSiteFailed(error));
      });
  };
};

export const shareTask = (taskId, accessToken) => {
  return (dispatch) => {
    axios
      .post(`/tasks/${taskId}/share`, {}, { headers: { 'encrypted-token': true, Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        dispatch(shareTaskSuccess(response.data));
      })
      .catch((error) => {
        dispatch(shareTaskFailed(error));
      });
  };
};

export const activatedShareTask = (code, siteId, clientId, accessToken) => {
  return (dispatch) => {
    axios
      .post(
        `/tasks/share/${code}/activate`,
        { siteId, clientId },
        { headers: { 'encrypted-token': true, Authorization: `Bearer ${accessToken}` } }
      )
      .then((response) => {
        dispatch(activatedShareTaskSuccess(response.data));
      })
      .catch((error) => {
        dispatch(activatedShareTaskFailed(error));
      });
  };
};
