import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import languageConverter, { languageMapper } from '../util/languageConverter';
import moment from 'moment';

export default function useIntl(namespaces = 'translation') {
  const language = useSelector(({ auth }) => auth.userInfo && auth.userInfo.preference && auth.userInfo.preference.language);
  const [lang, setLang] = useState(language || languageMapper.English );
  const { t, i18n } = useTranslation(namespaces);

  useEffect(() => {
    setLang(languageConverter(language));
  }, [language]);

  useEffect(() => {
    i18n.changeLanguage(languageConverter(lang));
    moment.locale(languageConverter(lang));
  }, [lang, i18n]);

  function setLanguage(lang) {
    setLang(languageConverter(lang));
  }

  function getLanguage() {
    return i18n.language;
  }

  return { t, setLanguage, getLanguage };
}