import axios from '../axios';

export const LOAD_LEGAL_CONTENTS_SUCCESS = 'LOAD_LEGAL_CONTENTS_SUCCESS';
export const LOAD_LEGAL_CONTENTS_FAILED = 'LOAD_LEGAL_CONTENTS_FAILED';
export const ACCEPT_LEGAL_CONTENTS_SUCCESS = 'ACCEPT_LEGAL_CONTENTS_SUCCESS';
export const ACCEPT_LEGAL_CONTENTS_FAILED = 'ACCEPT_LEGAL_CONTENTS_FAILED';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LEGAL_CONTENTS_SUCCESS:
      return {
        ...state,
        legalContents: action.data,
      };
    case LOAD_LEGAL_CONTENTS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case ACCEPT_LEGAL_CONTENTS_SUCCESS:
      return {
        ...state,
      };
    case ACCEPT_LEGAL_CONTENTS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export const acceptLegalContentSuccess = (data) => {
  return {
    type: ACCEPT_LEGAL_CONTENTS_SUCCESS,
    data,
  };
};

export const acceptLegalContentFailed = (error) => {
  return {
    type: ACCEPT_LEGAL_CONTENTS_FAILED,
    error,
  };
};

export const acceptLegalContent = (id) => {
  return (dispatch) => {
    axios
      .post(`/userconsents`, { legalContentId: id }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(acceptLegalContentSuccess(response.data));
      })
      .catch((error) => {
        dispatch(acceptLegalContentFailed(error));
      });
  };
};

export const loadLegalContentListSuccess = (data) => {
  return {
    type: LOAD_LEGAL_CONTENTS_SUCCESS,
    data,
  };
};

export const loadLegalContentListFailed = (error) => {
  return {
    type: LOAD_LEGAL_CONTENTS_FAILED,
    error,
  };
};

export const loadLegalContentList = () => {
  return (dispatch) => {
    axios
      .get(`/legalcontents`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(loadLegalContentListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadLegalContentListFailed(error));
      });
  };
};
