import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/th';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['htmlTag'],
    },
    react: {
      useSuspense: true,
    },
    fallbackLng: 'en',
    ns: [
      'analytic',
      'chemical-analytic',
      'chemical',
      'client',
      'dashboard',
      'drone',
      'flight',
      'loginpage',
      'navigation',
      'notfound',
      'organization',
      'payload',
      'plot',
      'report',
      'site',
      'sugarcane-analytic',
      'system',
      'task',
      'ui',
      'unit',
      'user',
    ],
    debug: true,
    interpolation: {
      format: function (value, format, lng) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
      escapeValue: false,
    },
  });

i18next.on('initialized', function (options) {
  const { fallbackLng, lng } = options;
  if (lng) {
    moment.locale(lng);
  } else {
    moment.locale(fallbackLng);
  }
});

i18next.on('languageChanged', function (lng) {
  moment.locale(lng);
});

export default i18next;
