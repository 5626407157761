import axios from '../axios';
import { Modal } from 'antd';
import socket from '../socketio';
import i18next from '../i18n';
import languageConverter from '../util/languageConverter';
import { notification } from 'antd';
import { ACCEPT_LEGAL_CONTENTS_SUCCESS } from './policy';
import { saveOrganizationPure, SAVE_ORGANIZATION_SUCCESS } from './organization';
import { get } from 'lodash';

// Actions
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const NOT_LOGIN = 'NOT_LOGIN';

export const UPDATE_MY_INFO_PROPERTY = 'UPDATE_MY_INFO_PROPERTY';
export const CLOSE_MY_INFO_FORM = 'CLOSE_MY_INFO_FORM';

export const EDIT_MY_INFO = 'EDIT_MY_INFO';
export const INIT_SAVE_MY_INFO = 'INIT_SAVE_MY_INFO';
export const SAVE_MY_INFO_SUCCESS = 'SAVE_MY_INFO_SUCCESS';
export const SAVE_MY_INFO_FAILED = 'SAVE_MY_INFO_FAILED';
export const DISABLE_REDIRECT_TO_CHANGE_PASSWORD = 'DISABLE_REDIRECT_TO_CHANGE_PASSWORD';
export const SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_SUCCESS = 'SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_SUCCESS';
export const SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_FAILED = 'SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_FAILED';
export const SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_RESET_ERROR = 'SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_RESET_ERROR';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        isAuthenticate: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...action.data.data,
        },
        isAuthenticate: true,
        isRequiredChangePassword: action.data.isRequiredChangePassword,
      };

    case DISABLE_REDIRECT_TO_CHANGE_PASSWORD:
      return {
        ...state,
        isRequiredChangePassword: false,
      };

    case LOGIN:
      return {
        ...state,
        error: undefined,
      };

    case NOT_LOGIN:
      return {
        error: undefined,
        isAuthenticate: false,
      };

    case EDIT_MY_INFO:
      return {
        ...state,
        form: {
          ...state.userInfo,
        },
      };

    case INIT_SAVE_MY_INFO:
      return {
        ...state,
        isSaving: true,
        error: null,
      };

    case UPDATE_MY_INFO_PROPERTY:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };

    case CLOSE_MY_INFO_FORM:
      return {
        ...state,
        form: undefined,
        isSaving: false,
        error: null,
      };

    case SAVE_MY_INFO_SUCCESS:
      return {
        ...state,
        isSaving: false,
        userInfo: {
          ...state.userInfo,
          ...action.data,
        },
        error: null,
      };

    case SAVE_MY_INFO_FAILED:
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };

    case SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSaveIndividualAccountSuccess: true,
        errors: null,
      };

    case SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_FAILED:
      return {
        ...state,
        isSaveIndividualAccountSuccess: false,
        errors: action.errors,
      };

    case SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_RESET_ERROR:
      return {
        ...state,
        isSaveIndividualAccountSuccess: undefined,
        errors: undefined,
      };

    case ACCEPT_LEGAL_CONTENTS_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          requireReviewConsent: false,
        },
      };

    default:
      return state;
  }
}

// Action Creators
export const loginProcessing = () => {
  return {
    type: LOGIN,
  };
};

export const loginSuccess = (data) => {
  localStorage.setItem('accessToken', data.accessToken);
  if (data.refreshToken) {
    localStorage.setItem('refreshToken', data.refreshToken);
  }
  socketAuth(data.accessToken);
  return {
    type: LOGIN_SUCCESS,
    data,
  };
};

export const loginFailed = (error, alert = true) => {
  if (alert) {
    Modal.error({
      title: i18next.t('loginpage:control.login_failed_alert_message.title', 'Invalid Login'),
      content: i18next.t('loginpage:control.login_failed_alert_message.content', 'Please try again'),
      className: 'sub2',
      okButtonProps: {
        id: 'invalid-login-confirm-button',
      },
    });
  }
  return {
    type: LOGIN_FAILED,
    error,
  };
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(loginProcessing());
    axios
      .post('/auth/local', data)
      .then((response) => {
        dispatch(loginSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

export const notLogin = () => {
  return {
    type: NOT_LOGIN,
  };
};

export const verifyJWT = () => {
  return (dispatch) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios
        .post('/auth/local', { accessToken: token, strategy: 'jwt' })
        .then((response) => {
          dispatch(loginSuccess(response.data));
        })
        .catch((error) => {
          if (
            get(error, 'response.data.code') === 401 &&
            get(error, 'response.data.name') === 'NotAuthenticated' &&
            get(error, 'response.data.data.name') === 'TokenExpiredError'
          ) {
            // try renew access token
            const refreshToken = localStorage.getItem('refreshToken');
            axios
              .post('/auth/local', { refreshToken, action: 'refresh', application: 'HiveGrid Website', strategy: 'jwt' })
              .then((response) => {
                dispatch(loginSuccess(response.data));
              })
              .catch((error) => {
                dispatch(loginFailed(error, false));
              });
          } else {
            dispatch(loginFailed(error, false));
          }
        });
    } else {
      dispatch(notLogin());
    }
  };
};

export const editMyInfo = () => {
  return {
    type: EDIT_MY_INFO,
  };
};

export const updateMyInfoProperty = (data) => {
  return {
    type: UPDATE_MY_INFO_PROPERTY,
    data,
  };
};

export const closeMyInfoForm = () => {
  return {
    type: CLOSE_MY_INFO_FORM,
  };
};

export const initSaveMyInfo = () => {
  return {
    type: INIT_SAVE_MY_INFO,
  };
};

export const saveMyInfoSuccess = (data) => {
  const language = data.preference && data.preference.language;
  notification['success']({
    message: i18next.t('user:control.notification.save_my_info_success_message.message', {
      defaultValue: 'Saved!',
      lng: languageConverter(language),
    }),
    description: i18next.t('user:control.notification.save_my_info_success_message.description', {
      defaultValue: 'Your settings have been updated.',
      lng: languageConverter(language),
    }),
    className: 'sub2',
  });
  return {
    type: SAVE_MY_INFO_SUCCESS,
    data,
  };
};

export const saveMyInfoFailed = (error) => {
  return {
    type: SAVE_MY_INFO_FAILED,
    error,
  };
};

// this will call user and organization reducer
export const saveMyInfoForIndividualAccount = (userData, organizationData) => {
  const { id: orgId, ...otherFields } = organizationData;

  return (dispatch) => {
    dispatch({
      type: SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_RESET_ERROR,
    });

    Promise.all([dispatch(saveMyInfoPure(userData)), dispatch(saveOrganizationPure(orgId, otherFields))]).then((res) => {
      if (res[0].type === SAVE_MY_INFO_SUCCESS && res[1].type === SAVE_ORGANIZATION_SUCCESS) {
        dispatch({
          type: SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_SUCCESS,
        });
      } else {
        dispatch({
          type: SAVE_MY_INFO_FOR_INDIVIDUAL_ACCOUNT_FAILED,
          error: res.filter((res) => res.error),
        });
      }
    });
  };
};

export const saveMyInfo = (data) => {
  return (dispatch) => {
    dispatch(initSaveMyInfo());

    axios
      .patch(`/me`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then((response) => {
        dispatch(saveMyInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveMyInfoFailed(error));
      });
  };
};

export const saveMyInfoPure = (data) => {
  return async (dispatch) => {
    dispatch(initSaveMyInfo());

    try {
      const response = await axios.patch(`/me`, data, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });
      return dispatch({
        type: SAVE_MY_INFO_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      return dispatch({
        type: SAVE_MY_INFO_FAILED,
        error,
      });
    }
  };
};

export const socketAuth = (token) => {
  socket.emit('create', 'auth/local', {
    strategy: 'jwt',
    accessToken: token,
  });
};

export const disableRedirectToChangePassword = () => {
  return {
    type: DISABLE_REDIRECT_TO_CHANGE_PASSWORD,
  };
};
