import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import withIntl from '../../../../hoc/withIntl';
import RelativePortal from '../../utils/relativePortal/RelativePortal';

import { HEADERSTATUS } from '../../../../enums/datepickerHeaderStatus';
import HeaderPicker from './HeaderPicker';
import CalendarPicker from './DatePicker';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import dayjs from 'dayjs';

const SelectBox = styled.div`
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 12px;
  user-select: none;
  cursor: default;

  background-color: ${(props) => (props.disabled ? props.theme.dark : props.theme.light)};
  color: ${(props) =>
    props.disabled || typeof props.value === 'undefined' || props.value === null ? props.theme.gray_500 : props.theme.gray_700};
`;

const Wrapper = styled.div`
  width: ${(props) => (props.width ? props.width : '288px')};
  position: relative;
  background-color: ${(props) => props.theme.light};
  .form-control {
    border: ${(props) => (props.isOpen ? `1px solid ${props.theme.secondary_400}` : `1px solid ${props.theme.border_select}`)};
    box-shadow: 0 0 0 1px ${(props) => (props.isOpen ? props.theme.secondary_400 : 'transparent')};
  }
  .deleteicon {
    display: ${(props) => (props.value && props.deleteable ? 'inline-block' : 'none')};
    position: absolute;
    top: calc(50% - 12px);
    right: 40px;
    color: ${(props) => props.isOpen && props.theme.secondary_400};
    user-select: none;
    cursor: default;

    &:hover {
      color: red;
    }
  }
  .trailingicon {
    position: absolute;
    top: calc(50% - 12px);
    right: 12px;
    color: ${(props) => props.isOpen && props.theme.secondary_400};
    user-select: none;
    cursor: default;
  }
  &:hover {
    .form-control {
      border: 1px solid ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.primary_800)};
    }

    ~ label,
    .trailingicon {
      color: ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.primary_800)};
    }
  }

  ~ label {
    color: ${(props) => (props.isOpen ? props.theme.secondary_400 : props.theme.gray_700)};
  }
`;

const DropDownWrapper = styled.div`
  background-color: ${(props) => props.theme.light};
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px;
  z-index: 1;
  cursor: default;
  user-select: none;

  .select-title {
    padding: 12px;
    border-bottom: 1px solid ${(props) => props.theme.border_line};
  }

  .select-body {
    max-height: 266px;
    overflow-y: auto;

    .select-option {
      padding-left: 24px;
      padding-right: 12px;
      min-height: 48px;
      display: flex;
      align-items: center;
      position: relative;

      :hover {
        background-color: ${(props) => props.theme.primary_50};
      }

      i.checked {
        position: absolute;
        right: 12px;
        color: ${(props) => props.theme.secondary_400};
      }
    }
  }

  .select-footer {
    padding-left: 24px;
  }
`;

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerStatus: null,
      statePrevNext: null,
      monthYearSelected: dayjs().startOf('month'),
      isOpen: false,
      value: props.value || null,
      pendingMonthYear: props.value || dayjs().startOf('month'),
    };

    this.setShowAsyncTimer = null;

    this.handleToggle = () => {
      const { isOpen } = this.state;
      this.setShowAsync(!isOpen);
    };

    this.handleHide = () => {
      this.setShowAsync(false);
    };
  }

  static getDerivedStateFromProps(props) {
    if ('value' in props && props.value) {
      return { value: dayjs(props.value) };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen && this.state.value) {
      // when the calendar dropdown is open, sync pendingMonthYear header with the selected value
      this.setState({ pendingMonthYear: this.state.value });
    }
  }

  handleChangeValue =
    (isOpen = false) =>
    (value) => {
      const { onChange } = this.props;
      if (!('value' in this.props)) {
        this.setState({ value });
      }

      if (onChange) {
        onChange({ target: { value: dayjs(value).toDate() } });
      }

      this.setState({ isOpen });
    };

  clearValue = () => {
    const { onChange } = this.props;
    this.setState({ value: null });
    onChange({ target: { value: null } });
  };

  handleOnPendingMonthYearValueChanged = (value, mode = null, statePrevNext = null) => {
    this.setState({ pendingMonthYear: value });
    this.setState({ statePrevNext });

    if (mode === this.state.headerStatus) {
      this.setState({ monthYearSelected: value });
    }

    if (mode === HEADERSTATUS.MONTH) {
      this.setState({ headerStatus: null });
    } else if (mode === HEADERSTATUS.YEAR) {
      this.setState({ headerStatus: HEADERSTATUS.MONTH });
    }
  };

  setShowAsync(show) {
    clearTimeout(this.setShowAsyncTimer);
    this.setShowAsyncTimer = setTimeout(() => {
      this.setState({ headerStatus: null });
      this.setState({ monthYearSelected: dayjs() });
      this.setState({ pendingMonthYear: dayjs() });
      this.setState({ isOpen: show });
    }, 0);
  }

  handleOnHeaderClicked = (status) => {
    const { headerStatus } = this.state;
    this.setState({ statePrevNext: null });

    if (status === null) {
      this.setState({ headerStatus: null });
    } else if (status === HEADERSTATUS.MONTH) {
      this.setState({ headerStatus: headerStatus !== HEADERSTATUS.MONTH ? HEADERSTATUS.MONTH : null });
    } else if (status === HEADERSTATUS.YEAR) {
      this.setState({ headerStatus: headerStatus !== HEADERSTATUS.YEAR ? HEADERSTATUS.YEAR : null });
    }
  };

  render() {
    const { headerStatus, statePrevNext, monthYearSelected, isOpen, value, pendingMonthYear } = this.state;
    const { placeholder, disabled, enablePastDate = true, t, disableBeforeDate = dayjs(), deleteable = false, id, width, displayPlaceHolderWhenDisabled = false, } = this.props;
    return (
      <Wrapper width={width} isOpen={isOpen} value={value} deleteable={deleteable}>
        <SelectBox id={id} className='form-control sub2' disabled={disabled} onClick={disabled ? null : this.handleToggle} value={value}>
          {displayPlaceHolderWhenDisabled && disabled
            ? placeholder
            : value && dayjs(value).isValid()
            ? t('ui:date_picker.select_date', {
                date: value.toDate().toLocaleDateString(),
                defaultValue: value.toDate().toLocaleDateString(),
              })
            : placeholder}
        </SelectBox>
        <i className='material-icons deleteicon' onClick={disabled ? null : this.clearValue.bind(this)}>
          close
        </i>
        <i className='material-icons trailingicon' onClick={disabled ? null : this.handleToggle}>
          calendar_today
        </i>
        {isOpen && (
          <RelativePortal component='div' left={0} top={8} right={0} fullWidth={true} onOutClick={this.handleHide} zIndex={200}>
            <DropDownWrapper>
              <HeaderPicker
                id={id}
                headerStatus={headerStatus}
                pendingMonthYear={pendingMonthYear}
                onHeaderClicked={this.handleOnHeaderClicked}
                onPendingMonthYearValueChanged={this.handleOnPendingMonthYearValueChanged}
              />
              {!headerStatus ? (
                <CalendarPicker
                  id={id}
                  value={value}
                  pendingMonthYear={pendingMonthYear}
                  handleChangeValue={this.handleChangeValue(false)}
                  enablePastDate={enablePastDate}
                  disableBeforeDate={disableBeforeDate}
                />
              ) : headerStatus === HEADERSTATUS.MONTH ? (
                <MonthPicker
                  id={id}
                  onPendingMonthYearValueChanged={this.handleOnPendingMonthYearValueChanged}
                  monthYearSelected={monthYearSelected}
                  value={value}
                  pendingMonthYear={pendingMonthYear}
                />
              ) : (
                headerStatus === HEADERSTATUS.YEAR && (
                  <YearPicker
                    id={id}
                    onPendingMonthYearValueChanged={this.handleOnPendingMonthYearValueChanged}
                    monthYearSelected={monthYearSelected}
                    value={value}
                    pendingMonthYear={pendingMonthYear}
                    statePrevNext={statePrevNext}
                  />
                )
              )}
            </DropDownWrapper>
          </RelativePortal>
        )}
      </Wrapper>
    );
  }
}

export default compose(withIntl('ui'))(DatePicker);
